import React, { ReactElement, useState } from "react"
import styles from "./Slider.module.scss"

interface Props {
	children: React.ReactElement<{ transformed: boolean, setTransformed: (val: boolean) => void }>[]
	startingTransformed?: boolean
}

const Slider: React.FC<Props> = ({ children, startingTransformed = false }) => {
	const [transformed, setTransformed] = useState<boolean>(startingTransformed)

	const childrenWithProp = React.Children.map(children, (child :ReactElement) =>
		React.cloneElement(child, { transformed, setTransformed })
	)

	return (
		<div className={styles.wrapper}>
			<div
				className={styles.slideContainer}
				style={{ transform: `translateX(${transformed ? "-50%" : 0})` }}
			>
				{childrenWithProp}
			</div>
		</div>
	)
}
export default Slider

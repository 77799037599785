import React from "react"
import { useHistory } from "react-router"
import { changePassword } from "../../apicalls/account/changePassword"
import { useGlobalContext } from "../../App"
import PageBase from "../common-components/PageBase"
import ChangePassword from "../PreferencesPage/ChangePassword"
import styles from "./ChangePasswordPage.module.scss"
import HearnokLogo from "../../assets/Hearnok_logo.png"
import LanguageSelect from "../LandingPage/LanguageSelect"

const ChangePasswordPage: React.FC = () => {
	const { getText, setNotification } = useGlobalContext()
	const history = useHistory()

	const passwordResetKey = window.location.hash.substring(1)

	const handleSave = async (newPassword: string) => {

		const res = await changePassword({ passwordResetKey, newPassword })
		if (res.status === "success") {
            setNotification({ title: getText("Password changed"), icon: "success" })
		}else{
			if(res.message === "Password reset key expired"){
				setNotification({ title: getText("Password reset key expired"), icon: "error" })
			}else{
				setNotification({ title: getText("Something went wrong"), icon: "error" })
			}
        }
		history.push({ pathname: "/" })
	}

	return (
		<PageBase>
			<div className={styles.container}>
				<LanguageSelect />
				<img className={styles.hearnokLogo} src={HearnokLogo} alt="Logo"/>
				<div className={styles.roundContainer}>
					<div className={styles.scrollableContainer}>
						<div className={styles.titleContainer}>
							<p className={styles.titleText}>{getText("New password label")}</p>
							<p className={styles.titleInfo}>{getText("Set new password")}</p>
						</div>
						<ChangePassword className={styles.passwordContainer} isResetting resetSubmit={handleSave} />
						<h5 onClick={() => history.push({ pathname: "/" })} className={styles.bottomLine}>
							{getText("Back to home")}
						</h5>
					</div>
				</div>
			</div>
		</PageBase>
	)
}
export default ChangePasswordPage

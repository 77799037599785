import React, { useState } from "react"
import { login } from "../../apicalls/auth/login"
import { socket, useGlobalContext } from "../../App"
import { OneLiners } from "../../common/texts"
import { LoginBody, UserClientData } from "../../common/types"
import { validateEmail } from "../../utils/functions"
import HearnokButton from "../common-components/HearnokButton"
import HearnokInput from "../common-components/HearnokInput"
import styles from "./Login.module.scss"

interface Props {
	transformed? :boolean
	setTransformed?: (val: boolean) => void
	setUser: (user: UserClientData) => void
	setIsResettingPassword: (val: boolean) => void
}

type LoginFormData = Omit<LoginBody, "socketID" | "userLang">

const validateLoginData = (data: LoginFormData, getText: (text: keyof OneLiners) => string) => {
	const errors = {} as LoginFormData
	errors.email = validateEmail(data.email) ? "" : getText("Incorrect email format")
	errors.password = data.password ? "" : getText("Field necessary")
	for (let error of Object.values(errors)) {
		if (error) return errors
	}
	return null
}

const Login: React.FC<Props> = ({ transformed, setTransformed, setUser, setIsResettingPassword }) => {
	const { getText, lang } = useGlobalContext()

	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [errors, setErrors] = useState<LoginFormData | null>(null)

	const handleLogin = async () => {
		let errors = validateLoginData({ email, password }, getText)
		setErrors(errors)
		if (!errors) {
			const res = await login({ email, password, socketID: socket.id, userLang: lang })
			if (res.message === "Invalid credentials") {
				errors = {
					email: getText("Invalid credentials info"),
					password: getText("Invalid credentials info"),
				}
				setErrors(errors)
				return
			}
			if (res.message === "Email not activated") {
				errors = { email: getText("Email not activated"), password: "" }
				setErrors(errors)
				return
			}
			if (res.payload) {
				setUser({ ...res.payload })
			}
		}
	}

	const toResetPassScreen = () => {
		setIsResettingPassword(true)
		setTransformed?.(true)
	}

	const handleKeyDown = (event: any) => {
		if (event.key === 'Enter') {
			handleLogin()
		}
	  };

	return (
		<div className={styles.container}>
			<div className={styles.scrollableContainer}>
				<div className={styles.welcomeContainer} tabIndex = {-1}>
					<p className={styles.welcomeText}>{getText("Welcome")}</p>
					<p className={styles.welcomeInfo}>{getText("Sign in or create an account")}</p>
				</div>

				<div className={styles.fieldsContainer}>
					<HearnokInput
						label={getText("Email field label")}
						//icon="person_outline"
						value={email}
						setValue={setEmail}
						className={styles.input}
						error={errors?.email}
						name="email"
						tabIndex = {transformed ? -1 : 0}
					/>
					<HearnokInput
						label={getText("Password field label")}
						//icon="lock_open"
						value={password}
						setValue={setPassword}
						error={errors?.password}
						type="password"
						name="password"
						tabIndex = {transformed ? -1 : 0}
						handleKeyDown = {handleKeyDown}
					/>
					<h5 onClick={toResetPassScreen} className={styles.forgotPass}>
						{getText("Forgot password?")}
					</h5>
				</div>
				<HearnokButton
					className={styles.button}
					label={getText("Login button")}
					handleClick={handleLogin}
				/>
				<h5 className={styles.noAcc} onClick={() => setTransformed?.(true)}>
					{getText("No account")}
				</h5>
			</div>
		</div>
	)
}
export default Login

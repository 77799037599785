import React from "react"
import { useHistory } from "react-router"
import { useAppContext, useGlobalContext } from "../../App"
import styles from "./TopBar.module.scss"

interface Props {
	settingsAction?: () => void
}

const TopBar: React.FC<Props> = ({ settingsAction }) => {
	const { getText } = useGlobalContext()
	const { user, backAction, setBackAction } = useAppContext()
	const history = useHistory()

	const customBack = () => {
		backAction?.backAction?.()
		setBackAction({ backAction: undefined })
	}

	const onSettingsClick = () => {
		if (settingsAction) settingsAction()
		else if (history.location.pathname !== "/preferences")
			history.push({ pathname: "/preferences" })
	}

	return (
		<div className={styles.container}>
			<i
				onClick={backAction?.backAction ? customBack : () => history.go(-1)}
				className="material-icons"
				style={{ visibility: history.location.pathname === "/" ? "hidden" : undefined }}
			>
				arrow_back
			</i>
			
			<p>{user.isGuest ? (user.displayName + " ("+getText("Guest")+")") : user.displayName}</p>
			<i className="material-icons" onClick={onSettingsClick}>
				tune
			</i>
		</div>
	)
}
export default TopBar

import axios from "axios"
import { ApiResponse } from "../../common/types"
import { APIBASEURL } from "../../config"
import { Message } from "../../common/types"

export async function sendTranscriptByEmail(data: { email: string; roomCode: number }) {
	const res = await axios(APIBASEURL + "send-transcript", {
		method: "POST",
		data,
		withCredentials: true,
	})

	return res.data as ApiResponse<null, Message[]>
}

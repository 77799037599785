import styles from "./HearnokRadioButtonGroup.module.scss"
import HearnokInputLabel from "./HearnokInputLabel"
import { SelectOptions } from "../../types"
import { Radio, RadioGroup, FormControlLabel } from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface Props {
	className?: string
	options: SelectOptions
	label: string
	infoText?: string
	valueInit: string
	setValue: (val: any) => void
	setCreateEnable: (val: any) => void
}



const HearnokRadioButtonGroup: React.FC<Props> = ({
	className,
	options,
	label,
	infoText,
	valueInit,
	setValue,
	setCreateEnable
}) => {
	const onOptionChange = (e :React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value as string)
		setCreateEnable(e.target.value)
	}
	/*const optionElements = options.map(({ label, value }, index) => {
		return (
            <div key = {index}>
                <label>
					<input type="radio" value = {value} checked={valueInit ===value} onChange={onOptionChange}></input> 
					{label}
				</label>
            </div>
		)
	})*/

	const optionElements = options.map(({ label, value }, index) => {
		return (
			<FormControlLabel
				key = {index}
				value={value} 
				control={
					<Radio
						icon={<CheckBoxOutlineBlankIcon />} // Icon when unchecked
						checkedIcon={<CheckBoxIcon />} // Icon when checked
					/>
				}
				label={label}
				className ={styles.formContainer}
			/>
		)
	})

	return (
		<div className={[className ?? "", styles.container].join(" ")}>
			{label ? <HearnokInputLabel label={label} infoText={infoText} /> : null}
			{/*<div className={styles.optionsContainer}>
            	{optionElements}
			</div>*/}
			<RadioGroup
				className={styles.optionsContainer}
				aria-label="icon-radio"
				name="icon-radio"
				value={valueInit}
				onChange={onOptionChange}
			>
				{optionElements}
			</RadioGroup>
		</div>
	)
}
export default HearnokRadioButtonGroup

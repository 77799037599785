import React, { useState } from "react"
import { changePassword } from "../../apicalls/account/changePassword"
import { useGlobalContext } from "../../App"
import { setProperNotification } from "../../utils/functions"
import { useChangeBackAction } from "../../utils/hooks"
import HearnokButton from "../common-components/HearnokButton"
import HearnokInput from "../common-components/HearnokInput"
import styles from "./ChangePassword.module.scss"

interface Props {
	setTransformed?: (val: boolean) => void
	className?: string
	isResetting?: boolean
	resetSubmit?: (newPassword: string) => void
	setRenderedPanelToNull?: () => void
}

const ChangePassword: React.FC<Props> = ({
	setTransformed,
	className,
	isResetting = false,
	resetSubmit,
	setRenderedPanelToNull,
}) => {
	const { getText, setNotification } = useGlobalContext()

	const [oldPassword, setOldpassword] = useState("")
	const [newPassword, setNewPassword] = useState("")
	const [newPasswordAgain, setNewPasswordAgain] = useState("")
	const [error, setError] = useState("")
	const [passwordInvalid, setPasswordInvalid] = useState(false)
	const [buttonEnabled, setButtonEnabled] = useState(false)

	const backAction = () => {
		setTransformed?.(false)
		setRenderedPanelToNull?.()
	}
	useChangeBackAction(backAction)

	const submit = async () => {
		if (newPassword !== newPasswordAgain) {
			setError(getText("Passwords are not the same"))
			return
		}else{
			if(newPassword.length < 8){
				setError(getText("Password too short"))
				return
			}
		}
		setError("")
		if (resetSubmit) resetSubmit(newPassword)
		else {
			const res = await changePassword({ oldPassword, newPassword })
			if (res.message === "Password invalid") {
				setPasswordInvalid(true)
				return
			}
			setPasswordInvalid(false)
			setProperNotification(
				setNotification,
				res,
				getText("Something went wrong"),
				getText("Password changed")
			)
		}
		setTransformed?.(false)
		setRenderedPanelToNull?.()
	}

	const handleOldPassword = (oldPassword: string) => {
		setOldpassword(oldPassword)
		if(oldPassword && newPassword && newPasswordAgain){
			setButtonEnabled(true)
		}else{
			setButtonEnabled(false)
		}
	}

	const handleNewPassword = (newPassword: string) => {
		setNewPassword(newPassword)
		if(!isResetting){
			if(oldPassword && newPassword && newPasswordAgain){
				setButtonEnabled(true)
			}else{
				setButtonEnabled(false)
			}
		}else{
			if(newPassword && newPasswordAgain){
				setButtonEnabled(true)
			}else{
				setButtonEnabled(false)
			}
		}
	}

	const handleNewPasswordAgain = (newPasswordAgain: string) => {
		setNewPasswordAgain(newPasswordAgain)
		if(!isResetting){
			if(oldPassword && newPassword && newPasswordAgain){
				setButtonEnabled(true)
			}else{
				setButtonEnabled(false)
			}
		}else{
			if(newPassword && newPasswordAgain){
				setButtonEnabled(true)
			}else{
				setButtonEnabled(false)
			}
		}
	}

	return (
		<div className={className || styles.container}>
			{isResetting ? null : (
				<HearnokInput
					value={oldPassword}
					setValue={handleOldPassword}
					type="password"
					label={getText("Old password label")}
					error={passwordInvalid ? getText("Invalid password") : undefined}
				/>
			)}
			<HearnokInput
				value={newPassword}
				setValue={handleNewPassword}
				type="password"
				label={getText("New password label")}
			/>
			<HearnokInput
				value={newPasswordAgain}
				setValue={handleNewPasswordAgain}
				type="password"
				label={getText("New password repeat label")}
				error={error.length !== 0 ? error : undefined}
			/>
			<HearnokButton
				label={getText("Save")}
				className={["centered", styles.button].join(" ")}
				handleClick={submit}
				disabled={!buttonEnabled}
			/>
		</div>
	)
}
export default ChangePassword

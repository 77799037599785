import React, { useEffect, useState } from "react"
import { getRegKeyStatus } from "../../apicalls/auth/getRegKeyStatus"
import { register } from "../../apicalls/auth/register"
import { useGlobalContext } from "../../App"
import { /*Langs,*/ RegisterBody, STTLanguage } from "../../common/types"
import HearnokButton from "../common-components/HearnokButton"
import HearnokInput from "../common-components/HearnokInput"
import HearnokSelect from "../common-components/HearnokSelect"
import { validateEmail, validatePassword } from "../../utils/functions"
import styles from "./Register.module.scss"
import { OneLiners } from "../../common/texts"
//import HearnokPopup from "../common-components/HearnokPopup"
import ErrorPopup from "../common-components/ErrorPopup"
import { useHistory } from "react-router"
import Slider3 from "../common-components/Slider3"
import { SelectOptions } from "../../types"
import { getGroupIDs } from "apicalls/misc/getGroupIDs"

interface Props {
	transformed?: boolean
	setTransformed?: (val: boolean) => void
	setRegKey: (regKey: string | null) => void
}



const validateRegistrationData = (
	data: RegisterBody,
	getText: (text: keyof OneLiners) => string
) => {
	const { email, password } = data
	const errors = {} as RegisterBody
	errors.email = validateEmail(email) ? "" : getText("Incorrect email format")
	errors.password = validatePassword(password) ? "" : getText("Password too short")
	for (let error of Object.values(errors)) {
		if (error) return errors
	}
	return null
}

const Register: React.FC<Props> = ({ transformed, setTransformed, setRegKey }) => {
	const { getText, lang, setNotification } = useGlobalContext()
	const history = useHistory()

	const [email, setEmail] = useState("")
	const [emailAgain, setEmailAgain] = useState("")
	const [emailError, setEmailError] = useState("")
	const [emailAgainError, setEmailAgainError] = useState("")

	const [password, setPassword] = useState("")
	const [passwordAgain, setPasswordAgain] = useState("")
	const [passwordError, setPasswordError] = useState("")
	const [passwordAgainError, setPasswordAgainError] = useState("")

	const [displayName, setDisplayName] = useState("")
	const [displayNameError, setDisplayNameError] = useState("")
	const [groupID, setGroupID] = useState(0)
	const [groupIDs, setGroupIDs] = useState<SelectOptions>([{value: 0}])
	const [sttLanguage, setSttLang] = useState<STTLanguage>(lang)

	const [company, setCompany] = useState("")
	const [speciality, setSpeciality] = useState("")
	const [phoneNumber, setPhoneNumber] = useState("")
	

	const [regKeyValid, setRegKeyValid] = useState(true)
	//const [errors, setErrors] = useState<RegisterBody | null>(null)
	const [registrationSuccessful, setRegistrationSuccessful] = useState<boolean>(false)

	const [pageState, setPageState] = useState(0)
	const [backButtonEnabled, setBackButtonEnabled] = useState(false)
	const [nextButtonText, setNextButtonText] = useState(getText("Next"))

	const numberOfPages = 4

	const regKey = window.location.hash.substring(1)

	useEffect(() => {
		getGroupIDs()
		.then(({payload}) => {
			const options :SelectOptions<number> = []
			payload!.groupIDs.forEach( (label, index) => {
				options.push({value: index, label: label})
			})
			setGroupIDs(options)
		});
		return () => {

		}
	}, [])

	const handleBackClick = () => {
		setTransformed?.(false)
		setTimeout(() => setRegKey(null), 500)
		history.replace("/")
	}

	useEffect(() => {
		if (regKey) {
			getRegKeyStatus(regKey).then(setRegKeyValid)
		}
		//console.log(regKey)
	}, [regKey])

	const handleRegister = async () => {
		const data: RegisterBody = {
			email,
			password,
			regKey,
			displayName,
			pageLanguage: lang,
			sttLanguage,
			translateLanguage: sttLanguage,
			sttService: "Google",
			groupID,
			company,
			speciality,
			position: "",
			phoneNumber,
			lookingFor: ""
		}
		const errors = validateRegistrationData(data, getText)
		//setErrors(errors)
		if (!errors) {
			const res = await register(data)
			console.log(res)
			switch(res.message){
				case "Registration key invalid":
					setNotification({ title: getText("Registration key invalid"), icon: "error" })
					setRegistrationSuccessful(false)
					setRegKeyValid(false)
					break;
				case "Email already in use":
					setNotification({ title: getText("Email already in use"), icon: "error" })
					setRegistrationSuccessful(false)
					break;
			}
			if (res.message === "Registration key invalid") setRegKeyValid(false)
			if (res.status === "success") setRegistrationSuccessful(true)
		}
	}

	const handleBackButton = () => {
		let currentState = pageState
		currentState--
		setPageState(currentState)
		setNextButtonText(getText("Next"))
		if(currentState === 0){
			setBackButtonEnabled(false)
		}else{
			setBackButtonEnabled(true)
		}
	}

	const handleNextButton = () => {
		let currentState = pageState
		if(currentState === 0){
			let emailError = ""
			let emailAgainError = ""
			if(validateEmail(email) && validateEmail(emailAgain)){
				if(email === emailAgain){
					currentState++
					setPageState(currentState)
					setBackButtonEnabled(true)
				}else{
					emailAgainError = getText("Emails are not the same")
				}
			}else{
				if(!validateEmail(email)){
					emailError=getText("Incorrect email format")
				}
				if(!validateEmail(emailAgain)){
					emailAgainError=getText("Incorrect email format")
				}
			}
			setEmailError(emailError)
			setEmailAgainError(emailAgainError)
		}else if(currentState === 1){
			let passwordError = ""
			let passwordAgainError = ""
			if(validatePassword(password) && validatePassword(passwordAgain)){
				if(password === passwordAgain){
					currentState++
					setPageState(currentState)
				}else{
					passwordAgainError = getText("Passwords are not the same")
				}
			}else{
				if(!validatePassword(password)){
					passwordError=getText("Password too short")
				}
				if(!validatePassword(passwordAgain)){
					passwordAgainError=getText("Password too short")
				}
			}
			setPasswordError(passwordError)
			setPasswordAgainError(passwordAgainError)
		}else if(currentState === 2){
			if(!displayName){
				setDisplayNameError(getText("Field necessary"))
			}else{
				setDisplayNameError("")
				currentState++
				setPageState(currentState)
				setNextButtonText(getText("Register"))
			}
		}else if(currentState === 3){
			handleRegister()
		}
	}

	const languageOptions: { value: STTLanguage; label: string }[] = [
		{ value: "en", label: getText("English") },
		{ value: "hu", label: getText("Hungarian") },
		{ value: "de", label: getText("German") },
		{ value: "fr", label: getText("French") },
		{ value: "nl", label: getText("Dutch") }
	]


	return (
		<div className={styles.container}>
			{registrationSuccessful ? (
				<>
					<div className={styles.successText}>{getText("Successful registration info")}</div>
					<h5 className={styles.bottomLine} onClick={handleBackClick}>
						{getText("Back to home")}
					</h5>
				</>
			) : (
				<div className={styles.scrollableContainer}>
					<div className={styles.titleContainer}>
						<p className={styles.titleText}>{getText("Register")}</p>
						<p className={styles.titleInfo}>{getText("Register info")}</p>
					</div>
					<div className={styles.fullSliderContainer}>
						<div className={styles.pageIndexContainer}>
							<div className={styles.indexDot} style={{ backgroundColor: pageState >= 0 ? "#1ad3d1" : "gray", width: pageState === 0 ? "14rem" : "0.8rem"}}></div>
							<div className={styles.indexDot} style={{ backgroundColor: pageState >= 1 ? "#1ad3d1" : "gray", width: pageState === 1 ? "14rem" : "0.8rem"}}></div>
							<div className={styles.indexDot} style={{ backgroundColor: pageState >= 2 ? "#1ad3d1" : "gray", width: pageState === 2 ? "14rem" : "0.8rem"}}></div>
							<div className={styles.indexDot} style={{ backgroundColor: pageState >= 3 ? "#1ad3d1" : "gray", width: pageState === 3 ? "14rem" : "0.8rem"}}></div>
						</div>
						<Slider3 state={pageState} numberOfStates={numberOfPages}>
							<div className={styles.slideContainer}>
								<div className={styles.fieldContainer}>
									<HearnokInput
										label={getText("Email field label")}
										icon="person_outline"
										value={email}
										setValue={setEmail}
										className={styles.input}
										error={emailError}
										tabIndex = {pageState ===0 ? 0 : -1}
									/>
									<HearnokInput
										label={getText("Email field repeat label")}
										icon="person_outline"
										value={emailAgain}
										setValue={setEmailAgain}
										className={styles.input}
										error={emailAgainError}
										tabIndex ={pageState ===0 ? 0 : -1}
									/>
								</div>
							</div>
							<div className={styles.slideContainer}>
								<div className={styles.fieldContainer}>
									<HearnokInput
										label={getText("Password field label")}
										icon="lock_open"
										value={password}
										setValue={setPassword}
										type="password"
										error={passwordError}
										tabIndex = {pageState === 1 ? 0 : -1}
									/>
									<HearnokInput
										label={getText("Password field repeat label")}
										icon="lock_open"
										value={passwordAgain}
										setValue={setPasswordAgain}
										type="password"
										error={passwordAgainError}
										tabIndex = {pageState === 1 ? 0 : -1}
									/>
								</div>
							</div>
							<div className={styles.slideContainer}>
								<div className={styles.fieldContainer}>
									<HearnokInput
										label={getText("Display name label")}
										value={displayName}
										setValue={setDisplayName}
										error={displayNameError}
										tabIndex = {pageState === 2 ? 0 : -1}
									/>
									<HearnokSelect
										options={groupIDs}
										value={groupID}
										setValue={setGroupID}
										label={getText("Group")}
										tabIndex = {pageState === 2 ? 0 : -1}
									/>
									<HearnokSelect
										label={getText("Transcription language")}
										options={languageOptions}
										infoText={getText("Register - change later info")}
										value={sttLanguage}
										setValue={setSttLang}
										tabIndex = {pageState === 2 ? 0 : -1}
									/>
								</div>
							</div>
							<div className={styles.slideContainer}>
								<div className={styles.fieldContainer}>
									<HearnokInput
										label={`${getText("Company")} (${getText("Optional").toLowerCase()})`}
										value={company}
										setValue={setCompany}
										tabIndex = {pageState === 3 ? 0 : -1}
									/>
									<HearnokInput
										label={`${getText("Speciality")} (${getText("Optional").toLowerCase()})`}
										value={speciality}
										setValue={setSpeciality}
										tabIndex = {pageState === 3 ? 0 : -1}
									/>
									<HearnokInput
										label={`${getText("Phone number")} (${getText("Optional").toLowerCase()})`}
										value={phoneNumber}
										setValue={setPhoneNumber}
										tabIndex = {pageState === 3 ? 0 : -1}
									/>
								</div>
							</div>
						</Slider3>
					</div>
					<div className={styles.buttonContainer}>
						<HearnokButton
							className={styles.button}
							label={getText("Back")}
							handleClick={handleBackButton}
							disabled={!backButtonEnabled}
						/>
						<HearnokButton
							className={styles.button}
							label={nextButtonText}
							handleClick={handleNextButton}
						/>
					</div>
					
					{/*<HearnokButton
						className={styles.button}
						label={getText("Register")}
						handleClick={handleRegister}
			/>*/}
					<h5 className={styles.bottomLine} onClick={handleBackClick}>
						{getText("Back to home")}
					</h5>
					{regKeyValid ? null : (
						<ErrorPopup
							errorMsg={getText("Invalid registration key")}
							errorText={getText("Invalid regkey explanation")}
							buttonLabel={getText("Back to home")}
							action={handleBackClick}
						/>
					)}
				</div>
			)}
		</div>
	)
}
export default Register

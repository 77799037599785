import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { useAppContext, useGlobalContext } from "../../App"
import PageBase from "../common-components/PageBase"
import styles from "./ConnectRoomPage.module.scss"
//import TopBar from "../common-components/TopBar"
import HearnokButton from "../common-components/HearnokButton"
import HearnokInput from "../common-components/HearnokInput"
import { isNumeric } from "../../utils/functions"
import { connectRoom } from "../../apicalls/room/connectRoom"
import { handleConnectedToRoom } from "../ChatRoomPage/ChatRoomPageWrapper"

const ConnectRoomPage: React.FC = () => {
	const { getText, setNotification } = useGlobalContext()
    const { setActiveChat } = useAppContext()
    const [roomLinkOrCode, setRoomLinkOrCode] = useState("")
	const [roomPasswordConnect, setRoomPasswordConnect] = useState("")
    const [connectEnabled, setConnectEnabled] = useState(false)
    const history = useHistory()

    //Activate and deactive connect button
	useEffect(() => {
		if (roomLinkOrCode) {
			const l = roomLinkOrCode.length
			//console.log(isNumeric(roomLinkOrCode.substring(l - 6)))
			if (
				roomLinkOrCode.substring(l - 14, l - 7) === "rooms/#" &&
				isNumeric(roomLinkOrCode.substring(l - 7))
			) {
				setConnectEnabled(true)
			} else if (l === 7 && isNumeric(roomLinkOrCode)) {
				setConnectEnabled(true)
			} else setConnectEnabled(false)
		} else setConnectEnabled(false)
	}, [roomLinkOrCode])

    const handleConnectRoom = async () => {
		const res = await connectRoom({
			code: parseInt(roomLinkOrCode.substring(roomLinkOrCode.length - 7)),
			password: roomPasswordConnect || undefined,
		})

		if (res.status === "success") {
			if (handleConnectedToRoom(res, setActiveChat, history)) return
		}

		if (res.message === "Room does not exist") {
			setNotification({ title: getText("Room does not exist"), icon: "error" })
			return
		}
		if (res.message === "Password invalid") {
			setNotification({ title: getText("Invalid password"), icon: "error" })
			return
		}
		setNotification({ title: getText("Something went wrong"), icon: "error" })
	}

    return (
		<PageBase topBar>
			{/*<TopBar />*/}
			<div className={styles.container}>
				<p className={styles.title}>{getText("Connect")}</p>
				<div className={styles.roundContainer}>
					<div className={styles.scrollableContainer}>
						<div className={styles.innerContainer}>
							<HearnokInput
								value={roomLinkOrCode}
								setValue={setRoomLinkOrCode}
								label={getText("Room link or code")}
							/>
							<HearnokInput
								value={roomPasswordConnect}
								setValue={setRoomPasswordConnect}
								label={`${getText("Room password")} (${getText("Optional").toLowerCase()})`}
							/>
						</div>
						<HearnokButton
							label={getText("Connect")}
							disabled={!connectEnabled}
							handleClick={handleConnectRoom}
						/>
					</div>
				</div>
			</div>
		</PageBase>
	)

}
export default ConnectRoomPage
import React from "react"
import { useHistory } from "react-router"
import { useAppContext, useGlobalContext } from "../../App"
import HearnokButton from "../common-components/HearnokButton"
import PageBase from "../common-components/PageBase"
import TopBar from "../common-components/TopBar"
import styles from "./HomePage.module.scss"
import HearnokLogo from "../../assets/Hearnok_logo.png"
import BniLogo from "../../assets/bni_logo.png"
import RatingPopup from "../common-components/RatingPopup"


const HomePage: React.FC = () => {
	const { getText, ratingPopupOpen } = useGlobalContext()
	const { user } = useAppContext()
	const history = useHistory()

	const handleConnectRoom = () => {
		history.push({ pathname: "/connect-room"})
	}

	const handleCreateRoom = () => {
		history.push({ pathname: "/create-room"})
	}

	const handleSearch = () => {
		history.push({ pathname: "/search"})
	}

	const handleSettings = () => {
		history.push({ pathname: "/preferences"})
	}

	/*const sendTestEmail = async () => {
		const res = await sendTranscriptByEmail({email: "kristof.honti@dev.mechatromotive.com", roomCode: 1480149})
	}*/

	/*const testPDF = async () => {
		const blob = await pdf(<MyDocument />).toBlob()
		saveAs(blob, 'untitled.pdf')
	}*/

	return (
		<PageBase>
			<TopBar />
			<div className={styles.container}>
				<div className={styles.logoContainer}>
					<img className={styles.hearnokLogo} src={HearnokLogo} alt="Logo"/>
					<div className={styles.bniLogoContainer} >
						<p className={styles.bniText}>{getText("Powered by")}</p>
						<img className={styles.bniLogo} src={BniLogo} alt="Logo"/>
					</div>
				</div>
				<div className={styles.roundContainer}>
					<div className={styles.welcomeContainer}>
						<p className={styles.welcomeText}>{getText("Welcome")}</p>
						<div className={styles.welcomeInfoContainer}>
							<p className={styles.welcomeInfo}>
								{getText("Welcome to the")}<strong> Hearnok X powered by BNI </strong>{getText("Platform")}
							</p>
						</div>
					</div>

					{!user.isGuest ? (
						<div className={styles.buttonContainer}>
							<HearnokButton
								label={getText("Connect to room")}
								handleClick={handleConnectRoom}
								className={styles.connectButton}
								icon = "hub"
							/>
							<HearnokButton
								label={getText("Create new room")}
								handleClick={handleCreateRoom}
								className={styles.createButton}
								icon = "chat"
							/>
							<HearnokButton
								label={getText("Search")}
								handleClick={handleSearch}
								className={styles.searchButton}
								icon = "plagiarism"
							/>
							<HearnokButton
								label={getText("Settings")}
								handleClick={handleSettings}
								className={styles.settingsButton}
								icon = "settings"
							/>
						</div>
					) : (
						<div className={styles.buttonContainer}>
							<HearnokButton
								label={getText("Connect to room")}
								handleClick={handleConnectRoom}
								className={styles.connectButton}
								icon = "hub"
							/>
							<HearnokButton
								label={getText("Settings")}
								handleClick={handleSettings}
								className={styles.settingsButton}
								icon = "settings"
							/>
						</div>
					)}
					
				</div>
				{/*<HearnokButton
					className={styles.button}
					label={"Test PDF"}
					handleClick={testPDF}
				/>
				<HearnokButton
					className={styles.button}
					label={"Test email"}
					handleClick={sendTestEmail}
				/>*/
				}
			</div>
			{ratingPopupOpen ? (
				<RatingPopup
					buttonLabel={getText("Send")}
					popupMsg={getText("Rating popup title")}
					popupText = {getText("Rating popup text")}
				/>): null}
		</PageBase>
	)
}
export default HomePage

import { useMediaQuery } from "@material-ui/core"
import React from "react"
import styles from "./PageBase.module.scss"
import TopBar from "./TopBar"

interface Props {
	className?: string
	topBar?: true
	bottomBar?: true
	topBarSettingsAction?: () => void
}

const PageBase: React.FC<Props> = ({
	children,
	className,
	topBar,
	bottomBar,
	topBarSettingsAction,
}) => {
	const isMobile = useMediaQuery("(max-width: 600px)")
	const isNormalWide = useMediaQuery("(max-width: 1920px)")

	/*const root = getComputedStyle(document.documentElement);
	console.log("CURRENT FONT SIZE: ", root.getPropertyValue('--base-font-size'))*/

	if(isNormalWide){
		document.documentElement.style.setProperty("--base-font-size", "16px")
	}else{
		document.documentElement.style.setProperty("--base-font-size", "32px")
	}


	if (isMobile)
		return (
			<div
				style={{
					paddingTop: topBar && "calc(var(--top-bar-height) + 1rem)",
					paddingBottom: bottomBar && "3rem",
				}}
				className={styles.container}
			>
				{topBar ? <TopBar settingsAction={topBarSettingsAction} /> : null}
				{children}
			</div>
		)
	return (
		<div className={[styles.container, className].join(" ")}>
			<div
				style={{
					paddingTop: topBar && "calc(var(--top-bar-height) + 0rem)",
					paddingBottom: bottomBar && "3rem",
				}}
				className={styles.innerDesktopContainer}
			>
				{topBar ? <TopBar settingsAction={topBarSettingsAction} /> : null}
				{children}
			</div>
		</div>
	)
}
export default PageBase

import axios from "axios"
import { APIBASEURL } from "../../config"

export const activateEmail = async (emailActKey: string) => {
	const res = await axios({
		method: "post",
		url: APIBASEURL + "activate-email",
		data: { emailActKey },
	})
	return res.data.status === "success"
}

import { logout } from "apicalls/auth/logout"
import { updatePreferences } from "apicalls/account/updatePreferences"
import React, { useState } from "react"
import { useHistory } from "react-router"
import { deleteAccount } from "../../apicalls/account/deleteAccount"
import { useAppContext, useGlobalContext } from "../../App"
import { OneLiners } from "../../common/texts"
import { STTLanguage, STTService, UserClientData, TranslateLanguage } from "../../common/types"
import { HearnokNotification, SelectOptions } from "../../types"
import HearnokButton from "../common-components/HearnokButton"
import HearnokInput from "../common-components/HearnokInput"
import HearnokSelect from "../common-components/HearnokSelect"
//import BalanceBlock from "./BalanceBlock"
import styles from "./PreferencesLeft.module.scss"
import { prefEditModes } from "./PreferencesPage"

export const sttServiceOptions: SelectOptions<STTService> = [
	{ value: "Google" },
	{ value: "Amazon Transcribe" },
	{ value: "Microsoft Azure" },
]

interface Props {
	setTransformed?: (val: boolean) => void
	setActiveEditMode: (val: prefEditModes) => void
}

export const useProfileUpdateAction = (
	setNotification: (n: HearnokNotification | null) => void,
	getText: (text: keyof OneLiners) => string,
	setUser: (val: UserClientData | null) => void
) => {
	const fn = async (displayName: string, sttLanguage: STTLanguage, translateLanguage: TranslateLanguage) => {
		const res = await updatePreferences({ displayName, sttLanguage, translateLanguage })
		if (res?.status === "success") {
			setNotification({ title: getText("Preferences successfully updated"), icon: "success" })
			setUser(res.payload || null)
		} else setNotification({ title: getText("Something went wrong"), icon: "error" })
	}
	return fn
}

const PreferencesLeft: React.FC<Props> = ({ setTransformed, setActiveEditMode }) => {
	const { user, setUser } = useAppContext()
	const { getText, setNotification } = useGlobalContext()
	const history = useHistory()

	const [displayName, setDisplayName] = useState(user.displayName)
	const [sttLanguage, setSttLang] = useState<STTLanguage>(user.sttLanguage)
	const [translateLang, setTranslateLang] = useState(user.translateLanguage)

	const sttLangOptions: SelectOptions<STTLanguage> = [
		{ value: "en", label: getText("English") },
		{ value: "hu", label: getText("Hungarian") },
		{ value: "de", label: getText("German") },
		{ value: "fr", label: getText("French") },
		{ value: "nl", label: getText("Dutch") }
	]

	const translateLangOptions: SelectOptions<TranslateLanguage> = [
		{ value: "en", label: getText("English") },
		{ value: "hu", label: getText("Hungarian") },
		{ value: "es", label: getText("Spanish") },
		{ value: "pt", label: getText("Portuguese") },
		{ value: "nl", label: getText("Dutch") },
		{ value: "de", label: getText("German") },
		{ value: "fr", label: getText("French") },
		{ value: "hi", label: getText("Hindi") },
		{ value: "it", label: getText("Italian") },
		{ value: "pl", label: getText("Polish") },
		{ value: "ru", label: getText("Russian") },
		{ value: "lv", label: getText("Latvian") },
		{ value: "lt", label: getText("Lithuanian") },
		{ value: "be", label: getText("Belarusian") },
		{ value: "et", label: getText("Estionian") },
		{ value: "zh-CN", label: getText("Chinese") },
	]

	const handleEditClick = (type: prefEditModes) => {
		if (type === "delete_acc") deleteAccount()
		setActiveEditMode(type)
		setTransformed?.(true)
	}

	const handleSaveClick = useProfileUpdateAction(setNotification, getText, setUser)

	const handleLogout = () => {
		logout()
		setUser(null)
		history.push({ pathname: "/"})
	}

	return (
		<div className={styles.container}>
			<div className={styles.slideContainer}>
				{/*<BalanceBlock />*/}
				<HearnokInput
					setValue={setDisplayName}
					value={displayName}
					label={getText("Display name label")}
					className={styles.inputfield}
				/>
				<HearnokSelect
					value={sttLanguage}
					setValue={setSttLang}
					label={getText("Transcription language")}
					options={sttLangOptions}
				/>
				<HearnokSelect
					options={translateLangOptions}
					value={translateLang}
					setValue={setTranslateLang}
					label={getText("Translation language")}
				/>
				{!user.isGuest ? (
					<>
						<h5 onClick={() => handleEditClick("pw")} className={styles.textButton}>{getText("Change password")}</h5>
						<h5 onClick={() => handleEditClick("email")}className={styles.textButton}>{getText("Change email")}</h5>
						<h5 onClick={() => handleEditClick("user_data")}className={styles.textButton}>{getText("Change user data")}</h5>
						<h5 onClick={() => handleEditClick("delete_acc")} className={styles.errorText}>
							{getText("Delete account")}
						</h5>
					</>
				) : null}
				<h5 onClick={handleLogout} className={styles.textButton}>{getText("Logout")}</h5>
				<HearnokButton
					handleClick={() => handleSaveClick(displayName, sttLanguage, translateLang)}
					className={styles.button}
					label={getText("Save")}
				/>
			</div>
		</div>
	)
}
export default PreferencesLeft

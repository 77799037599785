import React from "react"
import { useGlobalContext } from "../../App"
import styles from "./ErrorPopup.module.scss"
import HearnokPopup from "./HearnokPopup"
import ErrorIcon from '@mui/icons-material/Error';

interface Props {
	buttonLabel?: string
	errorMsg?: string
	errorText?: string
	action: () => void
}

const ErrorPopup: React.FC<Props> = ({ buttonLabel, errorMsg, errorText, action }) => {
	const { getText } = useGlobalContext()

	const content = (
		<div className={styles.container}>
			<p className={styles.errorMsg}>{errorMsg || getText("Something went wrong")}</p>
			<ErrorIcon className={styles.icon}/>
			{errorText ? <p className={styles.errorText}>{errorText}</p> : null}
		</div>
	)

	return (
		<HearnokPopup buttonLabel={buttonLabel || getText("Back")} content={content} buttonAction={action} />
	)
}
export default ErrorPopup

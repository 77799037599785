import React, { useEffect, useState, useRef } from "react"
import { useHistory } from "react-router";
import { useAppContext, useGlobalContext } from "../../App"
import styles from "./ExitPopup.module.scss"
import HearnokPopup from "../common-components/HearnokPopup"
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import HearnokButton2 from "components/common-components/HearnokButton2";
//import { getMessagesTxt } from "../../apicalls/room/getMessagesTxt"
import { sendTranscriptByEmail } from "../../apicalls/room/sendTranscriptByEmail"
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver'
import { HearnokPdfDocument } from "../common-components/HearnokPDF"

interface Props {
	buttonLabel?: string
	popupMsg: string
	popupText?: string
}

const ExitPopup: React.FC<Props> = ({ buttonLabel, popupMsg, popupText }) => {
	const { getText, setNotification, setRatingPopupOpen } = useGlobalContext()
    const { activeChat, user } = useAppContext()

    const history = useHistory();
    const [showPrompt, setShowPrompt] = useState(false);

    const unblockRef = useRef<any>(null);

    useEffect(() => {
        unblockRef.current = history.block((location) => {
            setShowPrompt(true);
            return false;
        });
        return () => {
            unblockRef.current && unblockRef.current();
        };
    }, [history]);

    function handleOK() {
        if (unblockRef) {
            unblockRef.current();
        }        
        setShowPrompt(false);
        history.push({ pathname: "/" })
        setTimeout(() => {
			setRatingPopupOpen(true)
		}, 600)
    }

    const handleCancel = (event : any) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
          //console.log('parent clicked');
          setShowPrompt(false);
        }
    };

    /*function handleCancel(event) {

        console.log(event.current)
        setShowPrompt(false);
    }*/

    async function downloadPDF() {
        if(!activeChat?.roomCode) return null
		
        /*const res = await getMessagesTxt(activeChat?.roomCode)
        if (res.status === "success" && res.payload !== undefined) {
            //console.log(res.payload)
			const doc = HearnokPdfDocument(res.payload.messages, user.pageLanguage)
            const blob = await pdf(doc).toBlob()
		    saveAs(blob, res.payload.roomName+ " - " + res.payload.roomDate + '.pdf')
		}*/

        const doc = HearnokPdfDocument(activeChat.messages, user.pageLanguage, user.translateLanguage)
        const blob = await pdf(doc).toBlob()
        let today = new Date().toLocaleDateString()
		saveAs(blob, activeChat.roomCode+ " - " + today+ '.pdf')
	}

    async function sendByEmail() {
        if(!activeChat?.roomCode) return null

		const res = await sendTranscriptByEmail({email: user.email, roomCode: activeChat?.roomCode})
		if (res.status === "success") {
            //console.log(res.payload)
            setNotification({ title: getText("Success"), icon: "success" })
		}else{
            setNotification({ title: getText("Something went wrong"), icon: "error" })
        }
	}

	const content = (
		<div className={styles.container}>
			<p className={styles.popupMsg}>{popupMsg}</p>
            <QuestionMarkIcon className={styles.icon} />
			{popupText ? <p className={styles.popupText}>{popupText}</p> : null}
            <HearnokButton2 label={getText("Send transcript by email")} icon = {"email"} handleClick = {sendByEmail}/>
            <HearnokButton2 label={getText("Download transcript")} icon = {"download"} handleClick = {downloadPDF}/>
		</div>
	)

	return (
        showPrompt ? (
		    <HearnokPopup buttonLabel={buttonLabel || getText("Back")} content={content} buttonAction={handleOK} closeAction = {handleCancel} contentAction = {handleCancel}/>
        ) : null
	)
}
export default ExitPopup
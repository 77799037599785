import axios from "axios"
import { ApiResponse, deleteAccountErrorMessages } from "../../common/types"
import { APIBASEURL } from "../../config"

export async function deleteAccount(deleteCode?: string) {
	const res = await axios(APIBASEURL + "delete-account", {
		method: "POST",
		data: deleteCode ? { deleteCode } : undefined,
		withCredentials: true,
	})

	return res.data as ApiResponse<deleteAccountErrorMessages>
}

import axios from "axios"
import { ApiResponse } from "../../common/types"
import { APIBASEURL } from "../../config"

export async function logout() {
	const res = await axios(APIBASEURL + "logout", {
		method: "POST",
		withCredentials: true,
	})

	//console.log(res)
	return res.data as ApiResponse
}

import { Tooltip } from "@material-ui/core"
import React from "react"
import styles from "./Info.module.scss"

interface Props {
	label: string
}

const Info: React.FC<Props> = ({ label }) => {
	return (
		<Tooltip title={label} enterTouchDelay={10}>
			<i className={["material-icons", styles.icon].join(" ")}>info</i>
		</Tooltip>
	)
}
export default Info

import { socket } from "../../App"
import { createRoomResponse } from "../../common/types"

export const createRoom = async (
	onSuccess: (args: createRoomResponse) => void,
	onError: (error?: createRoomResponse["error"]) => void,
	roomName: string,
	groupID: number,
	isModerated: boolean,
	password?: string,
	filePath?: string
) => {
	const cb = ({ error, activeChat }: createRoomResponse) => {
		if (error) {
			onError(error)
			return
		}
		if (activeChat) onSuccess({ activeChat })
	}
	socket.emit("create-room", { roomName, groupID, isModerated, password, filePath}, cb)
}

import React from "react"
import styles from "./HearnokButton2.module.scss"
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import DownloadIcon from '@mui/icons-material/Download';

interface Props {
	label: string
	className?: string
	handleClick?: () => void
	disabled?: boolean
	icon?: string
}

const HearnokButton2: React.FC<Props> = ({
	label,
	handleClick,
	className,
	disabled = false,
	icon
}) => {

    const getIconContent = () => {
		if (!icon) return null

        switch (icon) {
			case "email":
				return (
					<AlternateEmailIcon className={styles.icon} />
				)
			case "download":
				return (
					<DownloadIcon className={styles.icon} />
				)
			default:
				return null
		}
	}
    const iconContent = getIconContent()

	return (
		<div
			className={[className ?? "", styles.container, disabled ? styles.disabled : ""].join(" ")}
			onClick={disabled ? undefined : handleClick}
		>
            {icon ? (
                <>
                    <div className={styles.iconContainer}>
                        {iconContent}
                    </div>
                    <p className={styles.text}>{label}</p>
                </>
            ) : (
                <p className={styles.text}>{label}</p>
            )}
		</div>
	)
}
export default HearnokButton2

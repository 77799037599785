import axios from "axios"
import { ApiResponse, GuestLoginData, UserClientData } from "../../common/types"
import { APIBASEURL } from "../../config"

export async function registerGuest(data: GuestLoginData): Promise<ApiResponse<any, UserClientData>> {
	const res = await axios(APIBASEURL + "register-guest", {
		method: "POST",
		data,
		withCredentials: true,
	})
	return res.data
}

import React, { useState } from "react"
import { useAppContext, useGlobalContext } from "../../App"
import styles from "./BottomBar.module.scss"
import ChatSettings from "./ChatSettings"
import Share from "./Share"


interface ButtonProps {
	icon: string
	label: string
	onClick?: () => void
}

const Button: React.FC<ButtonProps> = ({ icon, label, onClick }) => {
	return (
		<div onClick={onClick} className={styles.buttonContainer}>
			<i className={"material-icons"}>{icon}</i>
			<p>{label}</p>
		</div>
	)
}

interface Props {
	stream: MediaStream | null
	volume: number
	setNoiseGateValue: (v: number) => void
	noiseGateValue: number
	setMuted: (val: boolean) => void
	muted: boolean
}

const BottomBar: React.FC<Props> = ({
	setMuted,
	setNoiseGateValue,
	volume,
	noiseGateValue,
	muted,
}) => {
	const { activeChat } = useAppContext()
	const { getText } = useGlobalContext()


	const [activeModalContent, setActiveModalContent] = useState<"share" | "more" | null>(null)

	const changeMicState = () => {
		if(!activeChat?.isModerated){
			setMuted(!muted)
		}
	}

	const content = activeModalContent ? (
		activeModalContent === "share" ? (
			<Share />
		) : (
			<ChatSettings
				noiseGateValue={noiseGateValue}
				volume={volume}
				setNoiseGateValue={setNoiseGateValue}
			/>
		)
	) : null

	return (
		<div className={[styles.container, activeModalContent ? styles.full : ""].join(" ")}>
			{activeModalContent ? (
				<>
					<i
						onClick={() => setActiveModalContent(null)}
						className={["material-icons", styles.closeModal].join(" ")}
					>
						expand_more
					</i>
					{content || null}
				</>
			) : (
				<>
					<div className={styles.wrapper}>
						<Button
							icon="group_add"
							label={getText("Share")}
							onClick={() => setActiveModalContent("share")}
						/>
					</div>
					<div className={styles.wrapper}>
						<i onClick={changeMicState} className={"material-icons"}>
							{!muted ? "mic_on" : "mic_off"}
						</i>
					</div>
					<div className={styles.wrapper}>
						<Button
							icon="more_vert"
							label={getText("More")}
							onClick={() => setActiveModalContent("more")}
						/>
					</div>
				</>
			)}
		</div>
	)
}
export default BottomBar

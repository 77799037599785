import React, { useState } from "react"
import { useGlobalContext } from "../../App"
import styles from "./ChatRoomDestroyedPopup.module.scss"
import HearnokPopup from "./HearnokPopup"
import { Rating, TextField } from '@mui/material';
import { sendRatingData } from "../../apicalls/misc/sendRatingData"

interface Props {
	buttonLabel?: string
	popupMsg: string
	popupText?: string
}

const RatingPopup: React.FC<Props> = ({ buttonLabel, popupMsg, popupText }) => {
	const { getText, setNotification, setRatingPopupOpen } = useGlobalContext()

    const [ratingValue, setRatingValue] = useState(0);
    const [ratingText, setRatingText] = useState("");

	async function sendRating() {
        const res = await sendRatingData({value: ratingValue, text: ratingText})
		if (res.status === "success") {
            setNotification({ title: getText("Thank you for rating us"), icon: "success" })
            setRatingPopupOpen(false)
		}else{
            if(res.message !== undefined){
                if(res.message === "No rating"){
                    setNotification({ title: getText("You have not set a rating"), icon: "error" })
                }else{
                    setNotification({ title: getText("Something went wrong"), icon: "error" })
                }
            }else{
                setNotification({ title: getText("Something went wrong"), icon: "error" })
            }
        }
        
	}

	async function setRating(newValue : number | null) {
        if(newValue == null){
            setRatingValue(0)
        }else{
            setRatingValue(newValue)
        }
        console.log(newValue)
	}


    const setText = (e: any) => {
        //console.log(e.target.value)
        setRatingText(e.target.value)
	}

    const handleCancel = (event : any) => {
        setRatingPopupOpen(false);
    };

    /*async function sendByEmail() {
        if(!activeChat?.roomCode) return null

		const res = await sendTranscriptByEmail({email: user.email, roomCode: activeChat?.roomCode})
		if (res.status === "success") {
            setNotification({ title: getText("Success"), icon: "success" })
		}else{
            setNotification({ title: getText("Something went wrong"), icon: "error" })
        }
	}*/

	const content = (
		<div className={styles.container}>
			<p className={styles.popupMsg}>{popupMsg}</p>
			{popupText ? <p className={styles.popupText}>{popupText}</p> : null}
            <Rating
                name="simple-controlled"
                value={ratingValue}
                size="large"
                onChange={(event, newValue) => {
                    setRating(newValue)
                }}
            />
            <TextField
                id="outlined-multiline-static"
                label={getText("Rate us")}
                multiline
                maxRows={4}
                onChange={setText}
                inputProps={{ maxLength: 250 }}
                sx = {{
                    "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-multiline.Mui-focused": {
                        "& > fieldset":{
                            borderColor: "#1ad3d1", //Border color
                            //border: "gray 0.125rem solid;", //Border color
                            //borderRadius: "0.6rem"
                        },
                    },
                    '& label.Mui-focused': {
                        color: '#1ad3d1',
                    }
                }}
            />
		</div>
	)

	return (
        <HearnokPopup 
            buttonAction={sendRating} 
            buttonLabel={buttonLabel || getText("Back to home")} 
            content={content}
            closeAction = {handleCancel}
        />
	)
}
export default RatingPopup

import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { useAppContext, useGlobalContext } from "../../App"
import PageBase from "../common-components/PageBase"
import styles from "./CreateRoomPage.module.scss"
import HearnokButton from "../common-components/HearnokButton"
import HearnokInput from "../common-components/HearnokInput"
import { createRoom } from "../../apicalls/room/createRoom"
import { createRoomResponse } from "../../common/types"
//import TopBar from "../common-components/TopBar"
import HearnokSelect from "../common-components/HearnokSelect"
import { SelectOptions } from "../../types"
import HearnokRadioButtonGroup from "components/common-components/HearnokRadioButtonGroup"
import HearnokInputLabel from "../common-components/HearnokInputLabel"
import UploadService from "../../apicalls/upload/fileUploadService";
//import { IFile } from "../../types";
import { getRoomTypes } from "apicalls/misc/getRoomTypes"
import { getGroupIDs } from "apicalls/misc/getGroupIDs"
import { oneLiners, OneLiners } from "common/texts"

const CreateRoomPage: React.FC = () => {
	const [roomName, setRoomName] = useState("")
	const [roomPassword, setRoomPassword] = useState("")
	const { setActiveChat, user, setUser, activeChat } = useAppContext()
	const { getText, setNotification } = useGlobalContext()
	const history = useHistory()
	const [groupID, setGroupID] = useState(0)
	const [roomType, setRoomType] = useState("0")
	const [filePath, setFilePath] = useState("")
	const [roomCode, setRoomCode] = useState(0)

	//File upload
	const [currentFile, setCurrentFile] = useState<File>();
 	const [progress, setProgress] = useState<number>(0);
  	const [message, setMessage] = useState<string>("");
  	//const [fileInfos, setFileInfos] = useState<Array<IFile>>([]);

	const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;
		const selectedFiles = files as FileList;
		setCurrentFile(selectedFiles?.[0]);
		setProgress(0);
	};

	const [roomTypes, setRoomTypes] = useState<SelectOptions>([])
	const [groupIDs, setGroupIDs] = useState<SelectOptions>([{value: 0}])

	const handleCreateRoom = () => {
		const onSuccess = ({ activeChat: resp }: createRoomResponse) => {
			if (resp) {
				if (!activeChat || activeChat.roomCode !== resp.roomCode) setActiveChat(resp)
				setUser({ ...user, colorInChat: 0 })
				setRoomCode(resp.roomCode!)
			}
		}
		const onError = (error?: createRoomResponse["error"]) => {
			const text = error ? getText("Insufficient funds") : getText("Something went wrong")
			setNotification({ title: text, icon: "error" })
		}
		const isModerated = !(roomType === "0")
		createRoom(onSuccess, onError, roomName, groupID, isModerated, roomPassword || undefined, filePath || undefined)
	}

	const upload = () => {
		setProgress(0);
		if (!currentFile) return;
		UploadService.upload(currentFile, (event: any) => {
			setProgress(Math.round((100 * event.loaded) / event.total));
		})
		.then((response) => {
			setMessage(response.data.message);
			setFilePath(response.data.payload)
			//setCreateEnabled(true)
			return UploadService.getFiles();
		})
		.catch((err) => {
			setProgress(0);

			if (err.response && err.response.data && err.response.data.message) {
				setMessage(err.response.data.message);
			} else {
				setMessage("Could not upload the File!");
			}
			setCurrentFile(undefined);
		});
	};

	/*const upload_old = () => {
		setProgress(0);
		if (!currentFile) return;
		if (!activeChat?.roomCode) return;
		UploadService.upload_old(currentFile, activeChat.roomCode.toString(), (event: any) => {
			setProgress(Math.round((100 * event.loaded) / event.total));
		})
		.then((response) => {
			setMessage(response.data.message);
			setFilePath(response.data.payload)
			return UploadService.getFiles();
		})
		.then((files) => {
			setFileInfos(files.data);
		})
		.catch((err) => {
			setProgress(0);

			if (err.response && err.response.data && err.response.data.message) {
				setMessage(err.response.data.message);
			} else {
				setMessage("Could not upload the File!");
			}

			setCurrentFile(undefined);
		});
	};*/

	/*useEffect(() => {
		UploadService.getFiles().then((response) => {
		  	setFileInfos(response.data);
		});
	}, []);*/

	useEffect(() => {
		if(roomCode){
			history.push({ pathname: "/rooms/#" + roomCode })
		}
	}, [roomCode, history])

	useEffect(() => {
		getRoomTypes()
		.then(({payload}) => {
			type OneLinerKeyArray = Array<keyof OneLiners>;
			const keysArray: OneLinerKeyArray = Object.keys(oneLiners) as OneLinerKeyArray;
			const options :SelectOptions<string> = []
			payload!.roomTypes.forEach( (label, index) => {
				let i = 0
				while (i < keysArray.length) {
					if(keysArray[i] === label){
						break;
					}else{
						i++
					}
				}
				options.push({value: index.toString(), label: getText(keysArray[i])})
			});

			setRoomTypes(options)
		});
		getGroupIDs()
		.then(({payload}) => {
			const options :SelectOptions<number> = []
			payload!.groupIDs.forEach( (label, index) => {
				options.push({value: index, label: label})
			})
			setGroupIDs(options)
			setGroupID(user.groupID)
		});
		return () => {

		}
	}, [getText, user.groupID])

	return (
		<PageBase topBar>
			{/*<TopBar />*/}
			<div className={styles.container}>
				<p className={styles.title}>{getText("Create new room")}</p>
				<div className={styles.roundContainer}>
					<div className={styles.scrollableContainer}>
						<div className={styles.innerContainer}>
							<HearnokInput
								value={roomName}
								setValue={setRoomName}
								label={`${getText("Room name")}`}
							/>
							<HearnokInput
								value={roomPassword}
								setValue={setRoomPassword}
								label={`${getText("Room password")} (${getText("Optional").toLowerCase()})`}
							/>
							<HearnokSelect
								options={groupIDs}
								value={groupID}
								setValue={setGroupID}
								label={getText("Group")}
							/>
							<HearnokRadioButtonGroup
								className={styles.meetingType}
								options={roomTypes}
								valueInit={roomType}
								setValue={setRoomType}
								label={getText("Meeting type")}
								setCreateEnable={(value) => {
									/*if(value == "0"){
										setCreateEnabled(true)
									}else{
										if(filePath==""){
											setCreateEnabled(false)
										}else{
											setCreateEnabled(true)
										}
									}*/
								}}
							/>
							{roomType !== "0" ? (
								<div className={styles.uploadContainer}>
									<HearnokInputLabel label={getText("Upload file")} />
									<div>
										<div className={styles.uploadSelector}>
											{/*<label className={styles.uploadSelectorLabel}>*/}
												<input type="file" onChange={selectFile} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
											{/*</label>*/}
										</div>
										<div className={styles.uploadButtonContainer}>
											<HearnokButton
												label={getText("Upload")}
												handleClick={upload}
												disabled={!currentFile}
											/>
										</div>
									</div>

									{currentFile && (
										<div className="progress my-3">
											<div
												className="progress-bar progress-bar-info"
												role="progressbar"
												aria-valuenow={progress}
												aria-valuemin={0}
												aria-valuemax={100}
												style={{ width: progress + "%" }}
											>
												{progress}%
											</div>
										</div>
									)}

									{message && (
										<div className="alert alert-secondary mt-3" role="alert">
											{message}
										</div>
									)}

								</div>
							) : null}
						</div>
						<HearnokButton
							label={getText("Create")}
							handleClick={handleCreateRoom}
							className={styles.createButton}
						/>
						{/*
						<h5 onClick={() => history.push({ pathname: "/" })} className={styles.bottomLine}>
							{getText("Back to home")}
									</h5>*/}
					</div>
				</div>
			</div>
		</PageBase>
	)
}
export default CreateRoomPage
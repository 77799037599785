import axios from "axios"
import { ApiResponse, connectRoomResponse, ConnectRoomResponseMessage } from "../../common/types"
import { APIBASEURL } from "../../config"

export const connectRoom = async (data: { code: number; password?: string; name?: string; email?: string }) => {
	const res = await axios(APIBASEURL + "connect-room", {
		method: "POST",
		withCredentials: true,
		data,
	})
	return (res.data as ApiResponse<ConnectRoomResponseMessage, connectRoomResponse>) || null
}

import React from "react"
import styles from "./Share.module.scss"
import { APPBASEURL } from "../../config"
import { useAppContext, useGlobalContext } from "../../App"
import QRCode from "qrcode.react"

const Share: React.FC = () => {
	const { getText } = useGlobalContext()
	const { activeChat } = useAppContext()

	const roomCode = window.location.hash.substring(1)

	const roomLink = APPBASEURL + "rooms/#" + roomCode

	const copy = () => {
		navigator.clipboard.writeText(roomLink)
	}

	return (
		<div className={styles.container}>
			<QRCode size={200} value={roomLink} />
			<div className={styles.flex}>
				<h5>{roomLink}</h5>
				<i onClick={copy} className="material-icons">
					content_copy
				</i>
			</div>
			<div className={styles.data}>
				<div className={styles.codeContainer}>
					<p>{getText("Room code")}:</p>
					<h5>{roomCode}</h5>
				</div>
				{activeChat?.password ? (
					<div className={styles.flex}>
						<p className={styles.passwordLabel}>{getText("Password field label")}: </p>
						<p>{activeChat.password}</p>
					</div>
				) : null}
			</div>
		</div>
	)
}
export default Share

import React from "react"
import ReactDOM from "react-dom"
import { HearnokNotification } from "../../types"
import styles from "./Notification.module.scss"

interface Props {
	title: string
	icon?: "success" | "error"
	info?: string
	disappearing?: boolean
	setNotification: (n: HearnokNotification | null) => void
}

const Notification: React.FC<Props> = ({
	title,
	icon,
	info,
	disappearing = false,
	setNotification,
}) => {
	const close = () => {
		const newNotification = { title, icon, info, disappearing: true }
		setNotification(newNotification)
		setTimeout(() => setNotification(null), 500)
	}

	return ReactDOM.createPortal(
		<div className={[styles.container, disappearing ? styles.disappearing : ""].join(" ")}>
			<div
				className={[
					styles.titleContainer,
					icon ? (icon === "error" ? styles.error : styles.green) : "",
				].join(" ")}
			>
				<h5>{title}</h5>
				{icon ? (
					<i className="material-icons">{icon === "error" ? "error_outline" : "check"}</i>
				) : null}
			</div>
			{info ? <div className={styles.text}>{info}</div> : null}
			<i onClick={close} className={["material-icons", styles.close].join(" ")}>
				close
			</i>
		</div>,
		document.body
	)
}

export default Notification

import axios from "axios"
import { ApiResponse } from "../../common/types"
import { APIBASEURL } from "../../config"
import { SearchResultType } from "../../types"

export async function getSearchRooms(data: {text: string, meetingName: string, groupID: number, startDate: string, endDate: string}) {
	const res = await axios({
		method: "POST",
        url: APIBASEURL + "search-rooms",
		withCredentials: true,
        data,
	})

	return res.data as ApiResponse<null, SearchResultType[]>
}
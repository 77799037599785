import axios from "axios"
import { ApiResponse } from "../../common/types"
import { APIBASEURL } from "../../config"

export async function requestEmailChange(data: { email: string; password: string }) {
	const res = await axios(APIBASEURL + "request-change-email", {
		method: "POST",
		data,
		withCredentials: true,
	})
	return res.data as ApiResponse
}

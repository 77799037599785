import React, { useState } from "react"
import styles from "./HearnokButton.module.scss"
import Loader from "../common-components/Loader"

interface Props {
	label: string
	className?: string
	handleClick?: () => void
	disabled?: boolean
	loadingProp?: boolean
	icon?: string
}

const HearnokButton: React.FC<Props> = ({
	label,
	handleClick,
	className,
	disabled = false,
	loadingProp,
	icon
}) => {
	const [loading, setLoading] = useState(false)

	const handleClickWithLoading = async () => {
		if (loading) return
		const result = handleClick?.() as any
		if (result instanceof Promise) {
			setLoading(true)
			await result
			setLoading(false)
		}
	}

	const relevantLoading = loadingProp !== undefined ? loadingProp : loading

	return (
		<div
			className={[className ?? "", styles.container, disabled ? styles.disabled : ""].join(" ")}
			onClick={disabled || relevantLoading ? undefined : handleClickWithLoading}
		>
			{relevantLoading ? (
				<div className={styles.loaderCont}>
					<Loader />
				</div>
			) : (
				<>
					{icon ? (
						<div className={styles.iconContainer}>
							<i className={["material-icons", styles.iconStyle].join(" ")}>{icon}</i>
							<h4>{label}</h4>
						</div>
					) : (
						<h4>{label}</h4>
					)}
				</>
				
			)}
		</div>
	)
}
export default HearnokButton

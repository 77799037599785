import React, { useState } from "react"
import { useHistory } from "react-router"
import { deleteAccount } from "../../apicalls/account/deleteAccount"
import { useAppContext, useGlobalContext } from "../../App"
import { useChangeBackAction } from "../../utils/hooks"
import HearnokButton from "../common-components/HearnokButton"
import HearnokInput from "../common-components/HearnokInput"
import styles from "./DeleteAccount.module.scss"

interface Props {
	setTransformed?: (val: boolean) => void
	setRenderedPanelToNull?: () => void
}

const DeleteAccount: React.FC<Props> = ({ setTransformed, setRenderedPanelToNull }) => {
	const { getText, setNotification } = useGlobalContext()
	const { setUser } = useAppContext()
	const history = useHistory()

	const [deleteCode, setDeleteCode] = useState("")

	const backAction = () => {
		setTransformed?.(false)
		setRenderedPanelToNull?.()
	}
	useChangeBackAction(backAction)

	const submit = async () => {
		const res = await deleteAccount(deleteCode)
		if (res.status === "success") {
			setUser(null)
			history.push({ pathname: "/" })
			return
		}
		if (res.message === "Delete code invalid") {
			setNotification({ title: getText("Delete code invalid"), icon: "error" })
		} else if (res.message === "Delete code expired") {
			setNotification({ icon: "error", title: getText("Delete code expired") })
		} else {
			setNotification({ title: getText("Something went wrong"), icon: "error" })
		}
	}

	return (
		<div className={styles.container}>
			<p className={styles.infoText}>{getText("Delete account info")}</p>
			<HearnokInput value={deleteCode} setValue={setDeleteCode} />
			<HearnokButton
				className={[styles.button, styles.deleteButton].join(" ")}
				label={getText("Delete account")}
				handleClick={submit}
			/>
		</div>
	)
}
export default DeleteAccount

import React, { useState } from "react"
import { useAppContext, useGlobalContext } from "../../App"
import { STTLanguage, TranslateLanguage } from "../../common/types"
import { SelectOptions } from "../../types"
import HearnokInput from "../common-components/HearnokInput"
import HearnokPopup from "../common-components/HearnokPopup"
import HearnokSelect from "../common-components/HearnokSelect"
import { useProfileUpdateAction } from "../PreferencesPage/PreferencesLeft"
import styles from "./SettingsPopup.module.scss"

interface Props {
	closeAction: () => void
}

const SettingsPopup: React.FC<Props> = ({ closeAction }) => {
	const { getText, setNotification} = useGlobalContext()
	const { user, setUser } = useAppContext()

	const [displayName, setDisplayName] = useState(user.displayName)
	const [sttLang, setSttLang] = useState(user.sttLanguage)
	const [translateLang, setTranslateLang] = useState(user.translateLanguage)

	const sttLangOptions: SelectOptions<STTLanguage> = [
		{ value: "en", label: getText("English") },
		{ value: "hu", label: getText("Hungarian") },
		{ value: "de", label: getText("German") },
		{ value: "fr", label: getText("French") },
		{ value: "nl", label: getText("Dutch") }
	]

	const translateLangOptions: SelectOptions<TranslateLanguage> = [
		{ value: "en", label: getText("English") },
		{ value: "hu", label: getText("Hungarian") },
		{ value: "es", label: getText("Spanish") },
		{ value: "pt", label: getText("Portuguese") },
		{ value: "nl", label: getText("Dutch") },
		{ value: "de", label: getText("German") },
		{ value: "fr", label: getText("French") },
		{ value: "hi", label: getText("Hindi") },
		{ value: "it", label: getText("Italian") },
		{ value: "pl", label: getText("Polish") },
		{ value: "ru", label: getText("Russian") },
		{ value: "lv", label: getText("Latvian") },
		{ value: "lt", label: getText("Lithuanian") },
		{ value: "be", label: getText("Belarusian") },
		{ value: "et", label: getText("Estionian") },
		{ value: "zh-CN", label: getText("Chinese") },
	]

	const saveProfileData = useProfileUpdateAction(setNotification, getText, setUser)

	const onSaveClick = async () => {
		await saveProfileData(displayName, sttLang, translateLang)
		closeAction()
	}

	const content = (
		<div className={styles.container}>
			<HearnokInput
				label={getText("Display name label")}
				value={displayName}
				setValue={setDisplayName}
			/>
			<HearnokSelect
				options={sttLangOptions}
				value={sttLang}
				setValue={setSttLang}
				label={getText("Transcription language")}
				className={styles.selectField}
			/>
			<HearnokSelect
				options={translateLangOptions}
				value={translateLang}
				setValue={setTranslateLang}
				label={getText("Translation language")}
				className={styles.selectField}
			/>
		</div>
	)

	return (
		<HearnokPopup
			closeAction={closeAction}
			buttonAction={onSaveClick}
			content={content}
			buttonLabel={getText("Save")}
		></HearnokPopup>
	)
}
export default SettingsPopup

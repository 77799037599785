import React, { useEffect, useState } from "react"
import styles from "./HearnokDropDownPage.module.scss"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';


interface Props {
	children: React.ReactElement<{ setTransformed: (val: boolean) => void }>
    className?: string
	value?: boolean
    handleClick?: () => void
	setValue?: (value: boolean) => void
    label: string
	maxHeigth: number
}

const HearnokDropDownPage: React.FC<Props> = ({ children, handleClick, label, className, maxHeigth, setValue, value }) => {
	const [transformed, setTransformed] = useState<boolean>(value || false)

	/*const childrenWithProp = React.Children.map(children, (child) =>
		//React.cloneElement(child, { setTransformed })
	)*/

    const handleClickInner = async () => {
		handleClick?.()
		setValue?.(!transformed)
        setTransformed(!transformed)
	}

	useEffect(() => {
		if(value !== undefined){
			setTransformed(value)
		}
	}, [value])

	return (
		<div className={[className ?? "", styles.container].join(" ")} style={{ height: !transformed ? "3rem" : (maxHeigth+1.5)+"rem", }}>
			<div
				className={styles.dropdownContainer}
				style={{ height: !transformed ? "0rem" : maxHeigth+"rem",/* paddingTop: !transformed ? "0rem" : "2rem"*/}}
			>
				
				<div className={styles.childElements}>
					{//childrenWithProp
					children}
				</div>
				{
				//transformed ? childrenWithProp : null
				//childrenWithProp
				}
			</div>
			<div className={styles.buttonContainer} onClick={handleClickInner}>
				<p className={styles.label}>
					{label}
				</p>
				{transformed ? <ExpandLessIcon className={styles.iconStyle}/> : <ExpandMoreIcon className={styles.iconStyle}/>}
			</div>
		</div>
	)
}
export default HearnokDropDownPage
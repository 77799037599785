import React, { useState } from "react"
import { UserClientData } from "../../common/types"
import PageBase from "../common-components/PageBase"
import Slider from "../common-components/Slider"
import ContinueAsGuest from "./ContinueAsGuest"
import ForgotPassword from "./ForgotPassword"
import styles from "./LandingPage.module.scss"
import LanguageSelect from "./LanguageSelect"
import Login from "./Login"
import Register from "./Register"
import HearnokLogo from "../../assets/Hearnok_logo.png"

interface Props {
	setUser: (user: UserClientData) => void
}

const LandingPage: React.FC<Props> = ({ setUser }) => {
	const [isResettingPassword, setIsResettingPassword] = useState<boolean>(false)
	const [regKey, setRegKey] = useState<string | null>(
		window.location.pathname === "/" ? window.location.hash.substring(1) : null
	)

	return (
		<PageBase>
			<div className={styles.container}>
				<LanguageSelect />
				{/*<Logo className={styles.logo} />*/}
				<img className={styles.hearnokLogo} src={HearnokLogo} alt="Logo"/>
				<div className={styles.roundContainer}>
					<Slider startingTransformed={!!regKey}>
						<Login setUser={setUser} setIsResettingPassword={setIsResettingPassword} />
						{isResettingPassword ? (
							<ForgotPassword setIsResettingPassword={setIsResettingPassword} />
						) : regKey ? (
							<Register setRegKey={setRegKey} />
						) : (
							<ContinueAsGuest setUser={setUser} />
						)}
					</Slider>
				</div>
			</div>
		</PageBase>
	)
}
export default LandingPage

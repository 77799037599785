import axios from "axios"
import { APIBASEURL } from "../../config"

export const setActiveUserID = async (data: { roomCode: number; activeUserID: number}) => {
	const res = await axios({
		method: "post",
		url: APIBASEURL + "set-active-user-id",
        withCredentials: true,
		data,
	})
	return res.data.status === "success"
}
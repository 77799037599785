import React, { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router"
import { useAppContext, useGlobalContext } from "../../App"
import ErrorPopup from "../common-components/ErrorPopup"
import PageBase from "../common-components/PageBase"
import AudioProcessor from "./AudioProcessor"
import BottomBar from "./BottomBar"
import styles from "./ChatRoomPage.module.scss"
import Messages from "./Messages"
import SettingsPopup from "./SettingsPopup"
import MicPopup from "./MicPopup"
import ExitPopup from "./ExitPopup"

import { useWakeLock } from 'react-screen-wake-lock';

let audioProcessor: AudioProcessor

const ChatRoomPage: React.FC = () => {
	const { activeChat, user } = useAppContext()
	const { getText } = useGlobalContext()
	const history = useHistory()
	const [initialized, setInitialized] = useState(false)
	const [loading, setLoading] = useState(false)

	const [settingsPopupOpen, setSettingsPopupOpen] = useState(false)

	const [displayVolume, setDisplayVolume] = useState(0)
	const [noiseGateValue, setNoiseGateValue] = useState(10)
	const [muted, setMuted] = useState(false)
	const noiseGateRef = useRef(noiseGateValue)
	const mutedRef = useRef(muted)

	const { /*isSupported, released,*/ request, release } = useWakeLock();

	useEffect(() => {
		noiseGateRef.current = noiseGateValue
	}, [noiseGateValue])

	useEffect(() => {
		mutedRef.current = muted
	}, [muted])

	const streamRef = useRef<MediaStream | null>(null)
	const isCreator = user.id === activeChat?.creatorID

	useEffect(() => {
		if (isCreator) window.onbeforeunload = () => true
		return () => {
			window.onbeforeunload = () => undefined
		}
	}, [isCreator])

	useEffect(() => {
		if(activeChat?.isModerated){
			if(activeChat?.activeUserID === user.id){
				setMuted(false)
			}else{
				setMuted(true)
			}
		}
	}, [activeChat, user.id])

	const initialize = () => {
		if (!initialized) {
			setLoading(true)
			audioProcessor = new AudioProcessor(noiseGateRef, mutedRef, setDisplayVolume)
			audioProcessor.initializeMediaStream(() => {
				setLoading(false)
				setInitialized(true)
			})
		}
	}

	useEffect(() => {
		request()
	}, [request])

	useEffect(() => {
		return () => {
			audioProcessor?.destroy()
			release()
		}
	}, [release])

	if (!activeChat) return <ErrorPopup action={() => history.push({ pathname: "/" })}/>

	return (
		<PageBase topBar topBarSettingsAction={() => setSettingsPopupOpen(true)}>
			<div className={styles.container}>
				<Messages/>
				<BottomBar
					stream={streamRef.current}
					volume={displayVolume}
					setNoiseGateValue={setNoiseGateValue}
					noiseGateValue={noiseGateValue}
					setMuted={setMuted}
					muted={muted}
				/>
				{!initialized ? (
					<div className={styles.info}>
						<MicPopup
							popupMsg={getText("Enable mic")}
							popupText={getText("Mic start info")}
							buttonLabel={getText("Enable")}
							action={initialize}
							loadingProp={loading}
						/>
					</div>
				) : null}

				<div className={styles.info}>
					{<ExitPopup
						popupMsg={getText("Confirm close room title")}
						popupText={getText("Confirm close room text")}
						buttonLabel={getText("Back to home")}
					/>}
				</div>
			</div>
			{settingsPopupOpen ? <SettingsPopup closeAction={() => setSettingsPopupOpen(false)} /> : null}
		</PageBase>
	)
}
export default ChatRoomPage

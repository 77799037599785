import React, { useState } from "react"
import { useGlobalContext } from "../../App"
import hunFlag from "../../assets/Flag_of_Hungary.svg"
import enFlag from "../../assets/Flag_of_the_United_Kingdom.svg"
//import esFlag from "../../assets/Flag_of_Spain.svg"
import { Langs } from "../../common/types"
import styles from "./LanguageSelect.module.scss"
const LanguageSelect: React.FC = () => {
	const { lang, setLang } = useGlobalContext()
	const [isOpen, setIsOpen] = useState(false)

	const setDefaultLanguage = (lang: Langs) => {
		setLang(lang)
		localStorage.setItem("prefLang", lang)
	}

	return (
		<div className={styles.container} onClick={() => setIsOpen(!isOpen)}>
			<img src={lang === "en" ? enFlag : hunFlag} alt="flag" />
			{isOpen ? (
				<div className={styles.otherFlags}>
					<img
						onClick={() => setDefaultLanguage(lang === "en" ? "hu" : "en")}
						src={lang === "en" ? hunFlag : enFlag }
						alt="flag"
					/>
				</div>
			) : null}
		</div>
	)
}
export default LanguageSelect

import { socket } from "../../App"
import { Queue } from "../../utils/classes"
import { volumeDisplayMultiplier } from "../../config"

class AudioSender extends Queue<Float32Array> {
	constructor() {
		super(5)
	}

	private createPCMBuffer(inputFrame: Float32Array) {
		const audioLength = inputFrame.length * 2
		const buffer = new ArrayBuffer(audioLength)
		const view = new DataView(buffer)
		this.floatTo16BitPCM(view, 0, inputFrame)
		//console.log(buffer.byteLength+"/"+view.byteLength)
		return buffer
	}

	private floatTo16BitPCM(view: DataView, offset: number, input: Float32Array): void {
		for (let i = 0; i < input.length; i++, offset += 2) {
			//console.log(input[i]);
			const s = Math.max(-1, Math.min(1, input[i]))
			view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true)
		}
	}

	/*private sum(input: Float32Array){
		return input[0]+input[10]+input[500]
	}*/

	send() {
		/*const test_buf = this.getArray().map((a) => this.sum(a))
		console.log("send1:"+test_buf)*/
		const buffers = this.getArray().map((a) => this.createPCMBuffer(a))
		const blob = new Blob(buffers)
		this.empty()
		socket.emit("audio-chunk", blob)
	}
}

class AudioProcessor {
	private audioContext: AudioContext
	private analyser: AnalyserNode
	private javascriptNode: ScriptProcessorNode
	private noiseGateRef: React.MutableRefObject<number>
	private mutedRef: React.MutableRefObject<boolean>
	private setVolumeValue: (v: number) => void
	private audioSender: AudioSender
	private stream: MediaStream | null = null
	private audioLevels: Queue<number>

	constructor(
		noiseGateRef: React.MutableRefObject<number>,
		mutedRef: React.MutableRefObject<boolean>,
		setVolumeValue: (v: number) => void
	) {
		const AC = window.AudioContext || (window as any).webkitAudioContext
		this.audioContext = new AC({ sampleRate: 44100 })
		this.analyser = this.audioContext.createAnalyser()
		this.javascriptNode = this.audioContext.createScriptProcessor(4096, 1, 1)
		this.noiseGateRef = noiseGateRef
		this.mutedRef = mutedRef
		this.setVolumeValue = setVolumeValue
		this.audioSender = new AudioSender()
		this.audioLevels = new Queue(5)
	}

	private processAudio(e: AudioProcessingEvent) {
		const average = this.getAverageSignalStrengthFromAnalyser()
		this.audioLevels.push(average)
		const shouldSendData = this.checkIfShouldSendData(average)
		this.setVolumeValue(volumeDisplayMultiplier * average)
		const inputFrame = e.inputBuffer.getChannelData(0)

		//The values ​​must be copied, otherwise the queue will be filled with the last array
		const float32 = new Float32Array(inputFrame.length)
		for (var i =0;i < inputFrame.length;i++){
			float32[i] = inputFrame[i]
		}
		this.audioSender.push(float32)
		if (shouldSendData) {
			this.audioSender.send()
		}
	}

	private getAverageSignalStrengthFromAnalyser = () => {
		const array = new Uint8Array(this.analyser.frequencyBinCount)
		this.analyser.getByteFrequencyData(array)
		let values = 0
		const length = array.length
		for (let i = 0; i < length; i++) {
			values += array[i]
		}
		const average = values / length
		return average
	}

	private checkIfShouldSendData = (actualAudioLevel: number) => {
		let speaking: boolean
		if (this.mutedRef.current){ 
			speaking = false
		} else if (actualAudioLevel > this.noiseGateRef.current) {
			speaking = true
		} else if (this.audioLevels.getArray().filter((num) => num > this.noiseGateRef.current).length === 0){
			speaking = false
		} else {
			speaking = true
		}
		return speaking
	}

	initializeMediaStream(setInitialized: () => void) {
		//console.log(navigator.mediaDevices)
		const constraints = {
			audio: {
			  noiseSuppression: true,
			  echoCancellation: true, // Optional: Enable echo cancellation
			},
			video: false,
		};
		navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
			this.stream = stream
			const microphone = this.audioContext.createMediaStreamSource(stream)
			microphone.connect(this.analyser)
			this.analyser.connect(this.javascriptNode)
			this.javascriptNode.connect(this.audioContext.destination)
			this.javascriptNode.addEventListener("audioprocess", (e) => this.processAudio(e))
			setInitialized()
		})
	}

	destroy() {
		this.stream?.getTracks().forEach((track) => track.stop())
		this.javascriptNode.removeEventListener("audioprocess", (e) => this.processAudio(e))
		this.javascriptNode.disconnect()
	}
}

export default AudioProcessor

import axios from "axios"
import { ApiResponse, Langs, LoginBody, UserClientData } from "../../common/types"
import { APIBASEURL } from "../../config"

export async function login(data: LoginBody): Promise<ApiResponse<any, UserClientData>>
export async function login(data: { socketID: string, userLang: Langs }): Promise<ApiResponse<any, UserClientData>>
export async function login(
	data: LoginBody | { socketID: string, userLang: Langs }
): Promise<ApiResponse<any, UserClientData>> {
	const res = await axios(APIBASEURL + "login", {
		method: "POST",
		data,
		withCredentials: true,
	})
	return res.data
}

import styles from "./HearnokSelect.module.scss"
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import HearnokInputLabel from "./HearnokInputLabel"
import { SelectOptions } from "../../types"
import React from 'react';

interface Props {
	options: SelectOptions
	label: string
	className?: string
	infoText?: string
	value: number | string
	setValue: (val: any) => void
	tabIndex?: number
}

const HearnokSelect: React.FC<Props> = ({
	options,
	label,
	className,
	infoText,
	value,
	setValue,
	tabIndex,
}) => {
	const optionElements = options.map(({ label, value }, index) => {
		return (
				<MenuItem key={index} value={value}>
					{label || value}
				</MenuItem>
		)
	})

	return (
		<div className={[className ?? "", styles.container].join(" ")}>
			<FormControl fullWidth>
				{label ? <HearnokInputLabel label={label} infoText={infoText} /> : null}
				<Select
					value={value}
					onChange={(e) => setValue(e.target.value as number | string)}
					className={styles.selectContainer}
					inputProps={{ tabIndex }}
				>
					{optionElements}
				</Select>
			</FormControl>
		</div>
	)
}
export default HearnokSelect

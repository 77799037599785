import axios from "axios"
import { ApiResponse, RegisterBody, registerResponseMessages } from "../../common/types"
import { APIBASEURL } from "../../config"

export const register = async (data: RegisterBody) => {
	const res = await axios(APIBASEURL + "register", {
		method: "POST",
		data,
		withCredentials: true,
	})
	return (res.data as ApiResponse<registerResponseMessages>) || null
}

import { requestEmailChange } from "apicalls/account/requestChangeEmail"
import React, { useState } from "react"
import { finishEmailChange } from "../../apicalls/account/finishEmailChange"
import { useGlobalContext } from "../../App"
import { useChangeBackAction } from "../../utils/hooks"
import HearnokButton from "../common-components/HearnokButton"
import HearnokInput from "../common-components/HearnokInput"
import styles from "./ChangePassword.module.scss"
import { validateEmail } from "../../utils/functions"

interface Props {
	setTransformed?: (val: boolean) => void
	setRenderedPanelToNull: () => void
}

const ChangeEmail: React.FC<Props> = ({ setTransformed, setRenderedPanelToNull }) => {
	const { getText, setNotification } = useGlobalContext()

	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [changeEmailKey, setChangeEmailKey] = useState("")
	const [emailSent, setEmailSent] = useState(false)
	const [emailError, setEmailError] = useState("")

	const backAction = () => {
		setTransformed?.(false)
		setRenderedPanelToNull?.()
	}
	useChangeBackAction(backAction)

	const submit = async () => {
		setEmailError("")
		if(validateEmail(email)){
			const res = await requestEmailChange({ email, password })
			if(res.status === "success"){
				setEmailSent(true)
			}else{
				setEmailSent(false)
				if(res.message === "Password invalid"){
					setNotification({ title: getText("Invalid password"), icon: "error" })
				} else if (res.message === "Email already in use"){
					setNotification({ title: getText("Email taken"), icon: "error" })
				}else{
					setNotification({ title: getText("Something went wrong"), icon: "error" })
				}
			}
			//console.log(res.message)
		}else{
			setEmailError(getText("Incorrect email format"))
			setEmailSent(false)
		}
	}

	const handleSendEmailChangeKey = async () => {
		const res = await finishEmailChange(changeEmailKey)
		if (res.status === "success") {
			setNotification({ title: getText("Success"), icon: "success" })
			backAction()
		} else if (res.message === "Invalid email change key")
			setNotification({ title: getText("Invalid email change key"), icon: "error" })
		else if (res.message === "Email already in use")
			setNotification({ title: getText("Email taken"), icon: "error" })
	}

	return (
		<div className={styles.container}>
			{!emailSent ? (
				<>
					<HearnokInput
						value={email}
						setValue={setEmail}
						label={getText("New email address label")}
						error={emailError.length !== 0 ? emailError: undefined}
					/>
					<HearnokInput
						value={password}
						setValue={setPassword}
						type="password"
						label={getText("Password field label")}
					/>
					<HearnokButton
						label={getText("Continue")}
						handleClick={submit}
						className={styles.button}
					/>
				</>
			) : (
				<>
					<div className={styles.info}>{getText("Email change info")}</div>
					<HearnokInput
						value={changeEmailKey}
						setValue={setChangeEmailKey}
						label={getText("Activation code")}
					/>
					<HearnokButton
						label={getText("Save")}
						handleClick={handleSendEmailChangeKey}
						className={styles.button}
					/>
				</>
			)}
		</div>
	)
}
export default ChangeEmail

import axios from "axios"
import { APIBASEURL } from "../../config"

export const setIsLogEnable = async (data: { roomCode: number; isLogEnable: boolean}) => {
	const res = await axios({
		method: "post",
		url: APIBASEURL + "set-is-log-enable",
        withCredentials: true,
		data,
	})
	return res.data.status === "success"
}
import React, { useState } from "react"
import { requestPasswordResetEmail } from "../../apicalls/auth/requestPasswordResetEmail"
import { useGlobalContext } from "../../App"
import HearnokButton from "../common-components/HearnokButton"
import HearnokInput from "../common-components/HearnokInput"
import styles from "./ForgotPassword.module.scss"
import { validateEmail } from "../../utils/functions"

interface Props {
	setTransformed?: (val: boolean) => void
	setIsResettingPassword: (val: boolean) => void
}

const ForgotPassword: React.FC<Props> = ({ setIsResettingPassword, setTransformed }) => {
	const { getText } = useGlobalContext()

	const [sendEnabled, setSendEnabled] = useState(false)

	const [email, setEmail] = useState("")
	const [text, setText] = useState("")

	const handleBack = () => {
		setTimeout(() => setIsResettingPassword(false), 500)
		setTransformed?.(false)
	}

	const handleEmailInput = (email: string) => {
		setEmail(email)
		if(validateEmail(email)){
			setSendEnabled(true)
		}else{
			setSendEnabled(false)
		}
	}

	const handleSendEmail = async () => {
		const res = await requestPasswordResetEmail(email)
		if (res.status === "success") setText(getText("Password reset email sent"))
		else setText(getText("Something went wrong"))
	}

	return (
		<div className={styles.container}>
			<div className={styles.scrollableContainer}>
				<p className={styles.title}>{getText("Forgotten Password")}</p>
				<div className={styles.innerContainer}>
					<p>{getText("Forgot password explanation")}</p>
					<p>{text}</p>
					<HearnokInput value={email} setValue={handleEmailInput} name="email" label="E-mail" />
					<HearnokButton label={getText("Send")} handleClick={handleSendEmail} disabled={!sendEnabled} />
					
				<p className={styles.infoText}>{getText("Forgot password after Send explanation")}</p>
				</div>
				<h5 onClick={handleBack} className={styles.bottomLine}>
					{getText("Back to home")}
				</h5>
			</div>
		</div>
	)
}
export default ForgotPassword

import React from "react"
import Info from "./Info"
import styles from "./HearnokInputLabel.module.scss"

interface Props {
	label: string
	infoText?: string
}

const HearnokInputLabel: React.FC<Props> = ({ label, infoText }) => {
	return (
		<p className={styles.label}>
			{label} {infoText ? <Info label={infoText} /> : null}
		</p>
	)
}
export default HearnokInputLabel

import React, { useState } from "react"
import PageBase from "../common-components/PageBase"
import Slider from "../common-components/Slider"
import ChangeEmail from "./ChangeEmail"
import ChangePassword from "./ChangePassword"
import ChangeUserData from "./ChangeUserData"
import DeleteAccount from "./DeleteAccount"
import PreferencesLeft from "./PreferencesLeft"
import styles from "./PreferencesPage.module.scss"
import { useGlobalContext } from "../../App"

export type prefEditModes = "pw" | "email" | "user_data" | "delete_acc" | null

const PreferencesPage: React.FC = () => {
	const [editingSide, setEditingSide] = useState<prefEditModes | null>(null)
	const { getText } = useGlobalContext()

	let EditSide
	switch (editingSide) {
		case "pw":
			EditSide = ChangePassword
			break
		case "email":
			EditSide = ChangeEmail
			break
		case "user_data":
			EditSide = ChangeUserData
			break
		case "delete_acc":
			EditSide = DeleteAccount
			break
		default:
			EditSide = null
			break
	}

	return (
		<PageBase topBar>
			<div className={styles.container}>
				<p className={styles.title}>{getText("Settings")}</p>
				<div className={styles.roundContainer}> 
					<div className={styles.scrollableContainer}>
						<Slider>
							<PreferencesLeft setActiveEditMode={setEditingSide} />
							{EditSide ? (
								<EditSide setRenderedPanelToNull={() => setTimeout(() => setEditingSide(null), 500)} />
							) : (
								<>{null}</>
							)}
						</Slider>
					</div>
				</div>
			</div>
		</PageBase>
	)
}
export default PreferencesPage

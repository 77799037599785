import { socket } from "../App"
import { ActiveChat, ApiResponse, Langs } from "../common/types"
import { HearnokNotification } from "../types"

export const getLang = (accountPrefLang?: Langs): Langs => {
	if (accountPrefLang) return accountPrefLang
	let locale
	if (localStorage.getItem("prefLang")) return localStorage.getItem("prefLang") as Langs

	if (navigator.languages !== undefined) locale = navigator.languages[0]
	else locale = navigator.language
	let prefLang = "en"
	switch (locale) {
		case "hu_HU":
			prefLang = "hu"
			break;
		default:
			prefLang = "en"
			break;
	}
	localStorage.setItem("prefLang", prefLang)
	return prefLang as Langs
}

export function validateEmail(mail: string) {
	if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
		return true
	}
	return false
}

export const validatePassword = (pw: string) => {
	return pw && pw.length > 7
}

export const setProperNotification = (
	setNotification: (n: HearnokNotification | null) => void,
	res: ApiResponse | null,
	errorText: string,
	successText: string
) => {
	if (res?.status === "success") {
		setNotification({ title: successText, icon: "success" })
	} else setNotification({ title: errorText, icon: "error" })
}

export function isNumeric(value: string) {
	return /^\d+$/.test(value)
}

export const onLeaveRoom = (setActiveChat: (a: ActiveChat | null) => void) => {
	socket.emit("left-room")
	setActiveChat(null)
}

export const toGivenNumberOfDigits = (numOfDigits: number, number: string | number) => {
	return ("0".repeat(numOfDigits) + number).slice(numOfDigits * -1)
}

import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { Message, TranslationText, Langs, TranslateLanguage } from "../../common/types";
import { oneLiners } from "../../common/texts";
import HearnokLogo from "../../assets/Hearnok_logo.png";
//import MyArial from "../../assets/arial.ttf"

Font.register({ family: 'Roboto', format: "truetype", fonts: [
    {
      src: 'https://fonts.cdnfonts.com/s/12558/trebuc.woff',
    }
  ]});

// Create styles
const pdfStyles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        justifyContent: 'flex-start',
        fontFamily: 'Roboto',
        padding: 32
    },
    section: {
        marginTop: 10,
    },
	waterMark: {
        position: "absolute",
        left: '0px',
		top: '337px',
		width: '600px',
		height: 'auto',
		transform: 'rotate(-60deg)',
		opacity: 0.1,
        marginHorizontal: 'auto',
        textAlign: "center",
        justifyContent: 'center',
    },
});

const getTranslationText = (translations: TranslationText[], translateLanguage: TranslateLanguage) => {
    let found = translations.find((translation) => translation.translationLang === translateLanguage)
    if(found){
        return found.translatedText
    }else{
        return null
    }
}

const getMessagesElements = (messages: Message[], lang: Langs, translateLanguage: TranslateLanguage) => {
    if (!messages) return null
    

    const messageElements = messages.map((message, index) => {
        if(message.senderID === "system"){
            const action = message.systemMemberChangeMsg?.action === "join" ? "Joined the room" : "Left the room"
            const text = oneLiners[action][lang]
            return (
                <View style={pdfStyles.section} key = {index}>
                    <Text>{message.displayName + " " + new Date(message.timestamp).toLocaleTimeString()}</Text>
                    <Text>{message.systemMemberChangeMsg?.name + " "+text}</Text>
                </View>
            )
        }else{
            return (
                <View style={pdfStyles.section} key = {index}>
                    <Text>{message.displayName + " " + new Date(message.timestamp).toLocaleTimeString()}</Text>
                    <Text>{message.text}</Text>
                    {message.translations.length !== 0 ? (
                        <Text>{getTranslationText(message.translations, translateLanguage)}</Text>
                    ) : null}
                </View>
            )
        }
    })
    return messageElements
}

const getPages = (messages: Message[], lang: Langs, translateLanguage: TranslateLanguage) => {
    let remainsArray = messages
    const maxMessageOnPage = 10
    let pages : JSX.Element[] = []
    let key = 0
    while(remainsArray.length>maxMessageOnPage){
        let pageMessages = remainsArray.slice(0, maxMessageOnPage)
        remainsArray.splice(0,maxMessageOnPage)
        const messageElements = getMessagesElements(pageMessages, lang, translateLanguage)
        pages.push(
            <Page size="A4" style={pdfStyles.page} key={key}>
                <View >
                    <Image style={pdfStyles.waterMark} src={HearnokLogo}></Image>
                </View>
                
                {messageElements}
            </Page>
        )
        key++
    }

    const messageElements = getMessagesElements(remainsArray, lang, translateLanguage)
    pages.push(
        <Page size="A4" style={pdfStyles.page} key={key}>
            <View >
                <Image style={pdfStyles.waterMark} src={HearnokLogo}></Image>
            </View>
            
            {messageElements}
        </Page>
    )
    return pages
}

// Create Document Component
export const HearnokPdfDocument = (messages: Message[], lang: Langs, translateLanguage: TranslateLanguage) => {
    
    const pages = getPages(messages, lang, translateLanguage)
    return(
        <Document>
            {pages}
        </Document>
    )
}
    

import axios from "axios"
import { APIBASEURL } from "../../config"

export const getRegKeyStatus = async (regKey: string) => {
	const res = await axios.get(APIBASEURL + "check-reg-key", {
		headers: {
			regKey,
		},
	})
	return res.data.status === "success"
}

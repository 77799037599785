import React, { useEffect, useState } from "react"
import { getChatColors } from "../../apicalls/misc/getChatColor"
import { getMessagesTxt } from "../../apicalls/room/getMessagesTxt"
import { setActiveUserID } from "../../apicalls/room/setActiveUserID"
import { setIsLogEnable } from "../../apicalls/room/setIsLogEnable"
import { useAppContext, useGlobalContext } from "../../App"
import styles from "./ChatSettings.module.scss"
import NoiseGateSetting from "./NoiseGateSetting"
import { getChatParticipants } from "../../apicalls/room/getChatParticipants"
import { Participant } from "../../common/types"
import HearnokButton from "../common-components/HearnokButton"
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver'
import { HearnokPdfDocument } from "../common-components/HearnokPDF"

const ChatMember: React.FC<{ color: string; displayName: string }> = ({ color, displayName }) => {
	return (
		<div className={styles.chatMemberContainer}>
			<div className={styles.circle} style={{ backgroundColor: color }}></div>
			<p>{displayName}</p>
		</div>
	)
}

interface Props {
	volume: number
	setNoiseGateValue: (v: number) => void
	noiseGateValue: number
}


const ChatSettings: React.FC<Props> = ({ volume, setNoiseGateValue, noiseGateValue }) => {
	const { getText } = useGlobalContext()
	const { activeChat, user } = useAppContext()

	const [colors, setColors] = useState<string[]>([])

	const [participants, setParticipants] = useState<Participant[]>([])

	const [logButtonText, setLogButtonText] = useState(activeChat?.isLogEnabled ? getText("Stop log") : getText("Start log"))
	const [isLogEnabled, setIsLogEnabled] = useState(activeChat?.isLogEnabled)
	const [isModerator, setIsModerator] = useState(false)

	
    useEffect(() => {
        if (!activeChat?.roomCode) return
		if (participants.length !== 0) return
		//if (activeChat?.creatorID !== user.id) return
		getChatParticipants(activeChat.roomCode)
        .then(({ payload }) => {
            //const moderator :Participant = {userID: user.id, userName: user.displayName, company: "", speciality: "", position: "", email: user.email, phoneNumber: "", lookingFor: "", status: ""}
            //payload!.unshift(moderator)
			if(payload){
				authParticipants(payload)
			}
            //console.log(payload)
        })
	}, []) //eslint-disable-line react-hooks/exhaustive-deps
	/*The upper comment disable the "React Hook useEffect has missing dependencies" warning*/

	useEffect(() => {
		getChatColors().then(({ payload }) => {
			setColors(payload!.colors)
		})
	}, [])

	useEffect(() => {
		authParticipants(participants)
	}, [activeChat]); //eslint-disable-line react-hooks/exhaustive-deps
	/*The upper comment disable the "React Hook useEffect has missing dependencies" warning*/

	const memberElements = activeChat?.members.map((member, index) => {
		return (
			<ChatMember color={colors[member.colorInChat]} displayName={member.isGuest ? (member.displayName + " ("+getText("Guest")+")") : member.displayName} key={index} />
		)
	})

	/*const moderatorMemberElements = participants.map((participant, index) => {
		return (
			<div className={styles.userBlock} key={index}>
				<p className={styles.name} key={index}>{participant.userName}</p>
					<div className={styles.wrapper}>
					<i onClick={changeMicState} className={"material-icons"}>
						{activeChat?.activeUserID == participant.userID ? "mic_on" : "mic_off"}
					</i>
				</div>
			</div>
		)
	})*/

	function changeMicState(participant:Participant) {
		if (!activeChat?.roomCode) return
		if (!participant.userID) return
		if(activeChat.activeUserID === participant.userID){

			setActiveUserID({ roomCode: activeChat.roomCode, activeUserID: 0})
		}else{
			setActiveUserID({ roomCode: activeChat.roomCode, activeUserID: participant.userID})
		}
	}

	function authParticipants(participants: Participant[]){
		if (activeChat?.members){
			const updatedPartcipants :Participant[] = []
			participants.forEach(function (participant) {
				//console.log(participant.userID)
				let found = false
				for (let i=0; i < activeChat.members.length && !found; i++){
					if(participant.email === activeChat.members[i].email){
						participant.userID = activeChat.members[i].id
						found=true
					}
				}
				if(!found){
					participant.userID = null
				}
				updatedPartcipants.push(participant)
			})
			activeChat.members.forEach(function (member) {
				let found = false
				for (let i=0; i < participants.length && !found; i++){
					if(participants[i].email === member.email){
						found=true
					}
				}
				if(!found){
					let newParticipant :Participant= {
						userID: member.id,
						userName: member.displayName,
						isGuest: member.isGuest,
						company: "",
						speciality: "",
						position: "",
						email: member.email,
						phoneNumber: "",
						lookingFor: "",
						status: "",
						isModerator: member.isModerator
					}
					updatedPartcipants.push(newParticipant)
				}
			})
			let i = 0
			let isModerator=false
			while(i<updatedPartcipants.length && !isModerator){
				if(updatedPartcipants[i].userID === user.id && updatedPartcipants[i].isModerator){
					isModerator=true
				}else{
					i++
				}
			}
			if(isModerator){
				setIsModerator(true)
			}else{
				setIsModerator(false)
			}
			
			setParticipants(updatedPartcipants)
		}else{
			setParticipants(participants)
		}
	}
	

	
	const moderatorMemberElements = participants.map((participant, index) => {
		return (
			<div className={styles.userBlock} key={index}>
				<div className={styles.userBlockName}>
					{participant.userID ? (
						<div className={styles.circle} style={{ backgroundColor: "#17fc03" }}></div>
					) : (
						<div className={styles.circle} style={{ backgroundColor: "#ff0000" }}></div>
					)}
					<p className={styles.name}>{participant.isGuest ? (participant.userName + " ("+getText("Guest")+")") : participant.userName}</p>
				</div>
				<div className={styles.wrapper}>
					<i onClick={() => changeMicState(participant)} className={["material-icons", styles.micIcon].join(" ")}>
						{activeChat?.activeUserID === participant.userID ? "mic_on" : "mic_off"}
					</i>
				</div>
			</div>
		)
	})

	/*const downloadTxt = async () => {
		if (!activeChat?.roomCode) return
		const res = await getMessagesTxt(activeChat.roomCode)
		if (res.status === "success") {
			const element = document.createElement("a")
			element.setAttribute(
				"href",
				"data:text/plain;charset=utf-8," + encodeURIComponent(res.payload || "")
			)
			element.setAttribute("download", new Date().getTime() + ".txt")
			element.style.display = "none"
			document.body.appendChild(element)
			element.click()
			document.body.removeChild(element)
		}
	}*/

	const downloadPDF = async () => {
		if (!activeChat?.roomCode) return
		const res = await getMessagesTxt(activeChat.roomCode)
		if (res.status === "success" && res.payload !== undefined) {
            //console.log(res.payload)
			const doc = HearnokPdfDocument(res.payload.messages, user.pageLanguage, user.translateLanguage)
            const blob = await pdf(doc).toBlob()
		    saveAs(blob, res.payload.roomName+ " - " + res.payload.roomDate + '.pdf')
		}
	}

	const muteAll = () => {
		if (!activeChat?.roomCode) return
		setActiveUserID({ roomCode: activeChat.roomCode, activeUserID: 0})
	}

	const handleLogButton = () => {
		if (!activeChat?.roomCode) return

		let logEnableState = isLogEnabled

		setIsLogEnable({roomCode: activeChat.roomCode, isLogEnable: !logEnableState})
		if(logEnableState){
			setLogButtonText(getText("Start log"))
		}else{
			setLogButtonText(getText("Stop log"))
		}
		setIsLogEnabled(!logEnableState)

	}

	return (
		<>
			{isModerator && activeChat?.isModerated ? (
				<> 
					<div className={styles.titleDiv}>
						<p className={styles.title}>{getText("Chat members")}</p>
						<HearnokButton
							label={getText("Mute all")}
							handleClick={muteAll}
							className={styles.button}
						/>
					</div>
					<div className={styles.userList}>
						{moderatorMemberElements}
					</div>
					<div className={styles.titleDiv}>
						<p className={styles.title}>{getText("Log title")}</p>
						<HearnokButton
							label={logButtonText}
							handleClick={handleLogButton}
							className={styles.button}
						/>
					</div>
				</>
			) : (
				<> 
					<p className={styles.title}>{getText("Chat members")}</p>
					{memberElements}
				</>
			)}
			
			<p className={styles.title}>{getText("Mic sensitivity")}</p>
			<NoiseGateSetting
				noiseGateValue={noiseGateValue}
				volume={volume}
				setNoiseGateValue={setNoiseGateValue}
			/>
			<h5 className={styles.export} onClick={downloadPDF}>
				{getText("Save messages")}
			</h5>
		</>
	)
}
export default ChatSettings

import React from "react"
import styles from "./Slider3.module.scss"

interface Props {
	children: React.ReactElement[]
	state?: number
    numberOfStates: number
}

const Slider3: React.FC<Props> = ({ children, state = 0, numberOfStates }) => {

	/*const childrenWithProp = React.Children.map(children, (child) =>
		React.cloneElement(child)
	)*/

	return (
		<div className={styles.wrapper}>
			<div
				className={styles.slideContainer}
				style={{ transform: `translateX(${-1*state*100/numberOfStates}%)`, width: numberOfStates +"00%" }}
			>
				{children}
			</div>
		</div>
	)
}
export default Slider3

import React from "react"
import styles from "./HearnokDatePicker.module.scss"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/hu";
import "dayjs/locale/en";
import {Dayjs} from "dayjs"
import HearnokInputLabel from "./HearnokInputLabel"

interface DatePickerProps {
    label?: string
    value: Dayjs
	setValue: (value: Dayjs) => void
}


const HearnokDatePicker: React.FC<DatePickerProps> = ({label, value,  setValue}) => {
	return (
        <div className={styles.container}>
            {label ? <HearnokInputLabel label={label}/> : null}
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                <DatePicker
                    value={value}
                    onChange = {d => {
                        if(d !=null) setValue(d)
                    }}
                    sx = {{
                        backgroundColor: "white",
                        color: "black",
                        borderRadius: "0.6rem",
                        "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd:hover": {
                            "& > fieldset":{
                                borderColor: "black", //Border hover color
                            },
                        },
                        "& .MuiIconButton-sizeMedium":{ //Calendar icon color
                            color: "black"
                        },
                        "& .MuiInputBase-input":{ //Label text color
                            //color: "black",
                            fontSize: "0.8rem",
                        },
                        "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd": {
                            "& > fieldset":{
                                //borderColor: "#AAAAAA", //Border color
                                border: "gray 0.125rem solid;", //Border color
                                borderRadius: "0.6rem"
                            },
                        },
                        
                    }}
                />
            </LocalizationProvider>
        </div>
	)
}
export default HearnokDatePicker
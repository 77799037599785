import React, { useState } from "react"
import { registerGuest } from "../../apicalls/auth/registerGuest"
import { socket, useGlobalContext } from "../../App"
import { LoginBody, UserClientData } from "../../common/types"
import HearnokButton from "../common-components/HearnokButton"
import HearnokInput from "../common-components/HearnokInput"
import styles from "./ContinueAsGuest.module.scss"
import TermsOfUseCheckbox from "./TermsOfUseCheckbox"
import { validateEmail } from "../../utils/functions"

interface Props {
	transformed?: boolean
	setTransformed?: (val: boolean) => void
	setUser: (user: UserClientData) => void
}

type LoginFormData = Omit<LoginBody, "socketID">

const ContinueAsGuest: React.FC<Props> = ({ transformed, setTransformed, setUser }) => {
	const { getText } = useGlobalContext()
	const [displayName, setDisplayName] = useState("")
	const [email, setEmail] = useState("")
	const [accepted, setAccepted] = useState(false)
	const [errors, setErrors] = useState<LoginFormData | null>(null)
	const [registerEnabled, setRegisterEnabled] = useState(false)

	const submit = async () => {
		const errors = {} as LoginFormData
		if (accepted && displayName && email) {
			if(validateEmail(email)){
				const res = await registerGuest({ displayName, email, socketID: socket.id })
				if(res.message){
					errors.email = res.message
					setErrors(errors)
				}
				if (res.payload) setUser(res.payload)
			}else{
				errors.email =  getText("Incorrect email format")
				setErrors(errors)
			}
		}
	}

	const handleEmailInput = (email: string) => {
		setEmail(email)
		if(validateEmail(email) && displayName && accepted){
			setRegisterEnabled(true)
		}else{
			setRegisterEnabled(false)
		}
	}

	const handleNameInput = (name: string) => {
		setDisplayName(name)
		if(validateEmail(email) && name && accepted){
			setRegisterEnabled(true)
		}else{
			setRegisterEnabled(false)
		}
	}

	const handleAcceptInput = (accept: boolean) => {
		setAccepted(accept)
		if(validateEmail(email) && displayName && accept){
			setRegisterEnabled(true)
		}else{
			setRegisterEnabled(false)
		}
	}

	return (
		<div className={styles.container}>
			<div className={styles.scrollableContainer}>
				<p className={styles.title}>{getText("Sign in as Guest")}</p>
				<div className={styles.innerContainer}>
					<p>{getText("Guest explanation")}</p>
					<HearnokInput
						value={displayName}
						setValue={handleNameInput}
						label={getText("Display name label")}
						tabIndex = {transformed ? 0 : -1}
					/>
					<HearnokInput
						value={email}
						setValue={handleEmailInput}
						label={getText("Email field label")}
						tabIndex = {transformed ? 0 : -1}
						error={errors?.email}
					/>
					<TermsOfUseCheckbox setValue={handleAcceptInput} value={accepted} />
					<HearnokButton
						className={styles.button}
						label={getText("Continue as guest")}
						handleClick={submit}
						disabled={!registerEnabled}
					/>
				</div>
				<h5 className={styles.noAcc} onClick={() => setTransformed?.(false)}>
					{getText("Already have account")}
				</h5>
			</div>
		</div>
	)
}
export default ContinueAsGuest

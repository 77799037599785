import React, { useEffect, useState } from "react"
import { getChatColors } from "../../apicalls/misc/getChatColor"
import { useAppContext, useGlobalContext } from "../../App"
import { ActiveChat, TranslationText } from "../../common/types"
import { toGivenNumberOfDigits } from "../../utils/functions"
import styles from "./Messages.module.scss"

class MemberColors {
	private membersColor: { [key: number]: number }

	constructor() {
		this.membersColor = {}
	}

	getColorID(memberID: number, activeChat: ActiveChat) {
		//console.log(this.membersColor)
		if (this.membersColor[memberID]) return this.membersColor[memberID]
		const colorID = activeChat.members.find((m) => m.id === memberID)?.colorInChat
		//console.log(colorID);
		if (!colorID) return 0
		this.membersColor[memberID] = colorID
		return colorID
	}
}

const colorIDs = new MemberColors()

const Messages: React.FC = () => {
	const { getText } = useGlobalContext()
	const { activeChat, user } = useAppContext()
	const [colors, setColors] = useState<string[]>([])

	useEffect(() => {
		getChatColors().then(({ payload }) => setColors(payload!.colors))
	}, [])

	if (!activeChat) return null

	const { messages } = activeChat

	const getTranslationText = (translations: TranslationText[]) => {
		let found = translations.find((translation) => translation.translationLang === user.translateLanguage)
		if(found){
			return found.translatedText
		}else{
			return null
		}
	}

	const getMessagesElements = () => {
		if (!messages) return null
		//console.log(messages)

		const reverseMessages = messages.slice().reverse()
		const messageElements = reverseMessages.map((message, index) => {
			if (message.senderID === "system") {
				const text = getText(
					message.systemMemberChangeMsg?.action === "join" ? "Joined the room" : "Left the room"
				)
				const d = new Date(message.timestamp)
				return (
					<div className={styles.systemMsg} key={index}>
						<p>{d.getHours() + ":" + toGivenNumberOfDigits(2, d.getMinutes())}</p>
						<p>{message.systemMemberChangeMsg?.name + " " + text}</p>
					</div>
				)
			}

			const isOwnMsg = message.senderID === user.id
			const shouldShowCircle =
				/*!isOwnMsg &&*/ reverseMessages?.[index + 1]?.senderID !== message.senderID
			//console.log(shouldShowCircle)
			return (
				<div key={index}>
					{shouldShowCircle ? <p className={`${styles.name} ${isOwnMsg ? styles.ownName : ""}`}>{message.displayName}</p> : null}
					<div className={styles.messageBlock}>
						{/*shouldShowCircle ? (
							<div
								style={{
									backgroundColor: colors[colorIDs.getColorID(message.senderID, activeChat)],
								}}
								className={styles.circle}
							/>
							) : null*/}
						<div
							style={{backgroundColor: colors[colorIDs.getColorID(message.senderID, activeChat)]}}
							className={`${styles.msg} ${isOwnMsg ? styles.ownMsg : ""}`}
						>
							<p className= {styles.originalText}>
								{message.text}
							</p>
							{message.translations.length !== 0 ? (
								<p className= {styles.translatedText}>
									{getTranslationText(message.translations)}
								</p>
							) : null}
							
						</div>
					</div>
				</div>
			)
		})
		return messageElements
	}
	const messageElements = getMessagesElements()

	return (
		<div className={styles.messagesContainer}>
			{messageElements}
			<div>&nbsp;</div>
		</div>
	)
}
export default Messages

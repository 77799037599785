import React from "react"
import { useGlobalContext } from "../../App"
import Checkbox from "../common-components/Checkbox"
import styles from "./TermsOfUseCheckbox.module.scss"

interface Props {
	value: boolean
	setValue: (val: boolean) => void
}

const TermsOfUseCheckbox: React.FC<Props> = ({ setValue, value }) => {
	const { lang } = useGlobalContext()

	const getLabel = () => {
		switch (lang) {
			case "hu":
				return (
					<>
						Elfogadom az <strong>ÁSZF</strong>-ben foglaltakat
					</>
				)
			default:
				return (
					<>
						I accept the <strong>Terms Of Use</strong>
					</>
				)
		}
	}

	return (
		<Checkbox label={getLabel()} value={value} setValue={setValue} className={styles.container} />
	)
}
export default TermsOfUseCheckbox

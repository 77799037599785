import axios from "axios"
import { ApiResponse } from "../../common/types"
import { APIBASEURL } from "../../config"
import { Message } from "../../common/types"

export async function getMessagesTxt(roomCode: number) {
	const res = await axios(APIBASEURL + "export/" + roomCode, {
		method: "GET",
		withCredentials: true,
	})

	return res.data as ApiResponse<null, {messages: Message[], roomName: string, roomDate: string }>
}

import React from "react"
import styles from "./HearnokInput.module.scss"
import HearnokInputLabel from "./HearnokInputLabel"

interface Props {
	icon?: string
	label?: string
	className?: string
	value: string
	setValue: (value: string) => void
	type?: "password" | "text"
	infoText?: string
	placeholder?: string
	error?: string
	name?: string
	tabIndex?: number
	handleKeyDown?: (event: any) => void
}

const HearnokInput: React.FC<Props> = ({
	icon,
	label,
	setValue,
	value,
	className,
	type = "text",
	infoText,
	placeholder,
	error,
	name,
	tabIndex,
	handleKeyDown
}) => {
	return (
		<div className={[className ?? "", styles.container].join(" ")}>
			{label ? <HearnokInputLabel label={label} infoText={infoText} /> : null}
			<div className={[styles.inputContainer, error ? styles.errorInput : ""].join(" ")}>
				{icon ? <i className="material-icons">{icon}</i> : null}
				<input
					name={name}
					value={value}
					onChange={({ target }) => setValue(target.value)}
					type={type}
					tabIndex={tabIndex}
					placeholder={placeholder}
					onKeyDown={handleKeyDown}
				/>
			</div>
			<p className={styles.error}>
				{error ? (
					<>
						<i className="material-icons">error</i>
						{error}
					</>
				) : (
					""
				)}
			</p>
		</div>
	)
}
export default HearnokInput

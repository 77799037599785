import axios from "axios"
import { ApiResponse } from "../../common/types"
import { APIBASEURL } from "../../config"

export async function getChatColors() {
	const res = await axios(APIBASEURL + "chat-colors", {
		method: "GET",
	})
	return res.data as ApiResponse<any, { colors: string[] }>
}

import axios from "axios"
import { ApiResponse } from "../../common/types"
import { APIBASEURL } from "../../config"

interface passwordResetData {
	passwordResetKey: string
	newPassword: string
}

interface passwordChangeData {
	oldPassword: string
	newPassword: string
}

export async function changePassword(data: passwordChangeData | passwordResetData) {
	const res = await axios(APIBASEURL + "change-password", {
		method: "POST",
		data,
		withCredentials: true,
	})

	return res.data as ApiResponse
}

import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import styles from "./EmailActivationPage.module.scss"
import { activateEmail } from "../../apicalls/auth/activateEmail"
import PageBase from "../common-components/PageBase"
import { useGlobalContext } from "../../App"
import HearnokButton from "../common-components/HearnokButton"
import Loader from "../common-components/Loader"
const EmailActivationPage: React.FC = () => {
	const history = useHistory()
	const { getText, setNotification } = useGlobalContext()
	const [activationSuccessful, setActivationSuccessful] = useState<boolean | null>(null)

	const emailActKey = window.location.hash.substring(1)

	if (!emailActKey) history.push({ pathname: "/" })

	useEffect(() => {
		activateEmail(emailActKey).then((success) => {
			if (!success) setActivationSuccessful(false)
			else {
				setNotification({ title: getText("Activation successful"), icon: "success" })
				history.push({ pathname: "/" })
			}
		})
	}, [history, emailActKey, getText, setNotification])

	if (activationSuccessful === false)
		return (
			<PageBase>
				<div className={styles.container}>
					<p>{getText("Activation unsuccessful info")}</p>
					<HearnokButton
						label={getText("Back to home")}
						handleClick={() => history.push({ pathname: "/" })}
					/>
				</div>
			</PageBase>
		)

	if (activationSuccessful) {
		return (
			<PageBase>
				<h5>{getText("Activation successful")}</h5>
			</PageBase>
		)
	}

	return (
		<PageBase>
			<div className={styles.loaderContainer}>
				<Loader />
			</div>
		</PageBase>
	)
}
export default EmailActivationPage

import React from "react"
import { useHistory } from "react-router"
import { useAppContext, useGlobalContext } from "../../App"
import styles from "./ChatRoomDestroyedPopup.module.scss"
import HearnokPopup from "./HearnokPopup"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HearnokButton2 from "components/common-components/HearnokButton2";
import { getMessagesTxt } from "../../apicalls/room/getMessagesTxt"
import { sendTranscriptByEmail } from "../../apicalls/room/sendTranscriptByEmail"
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver'
import { HearnokPdfDocument } from "../common-components/HearnokPDF"

interface Props {
	buttonLabel?: string
	popupMsg: string
	popupText?: string
}

const ChatRoomDestroyedPopup: React.FC<Props> = ({
	buttonLabel, popupMsg, popupText
}) => {
	const { setActiveChat } = useAppContext()
	const history = useHistory()

	const { getText, setNotification, setRatingPopupOpen } = useGlobalContext()
    const { activeChat, user } = useAppContext()

	const onClick = () => {
		setActiveChat(null)
		setTimeout(() => {
			history.push({ pathname: "/" })
		}, 10)
		setTimeout(() => {
			setRatingPopupOpen(true)
		}, 600)
	}

	async function downloadPDF() {
        if(!activeChat?.roomCode) return null
		const res = await getMessagesTxt(activeChat?.roomCode)

        if (res.status === "success" && res.payload !== undefined) {
            //console.log(res.payload)
			const doc = HearnokPdfDocument(res.payload.messages, user.pageLanguage, user.translateLanguage)
            const blob = await pdf(doc).toBlob()
		    saveAs(blob, res.payload.roomName+ " - " + res.payload.roomDate + '.pdf')
		}
	}

    async function sendByEmail() {
        if(!activeChat?.roomCode) return null

		const res = await sendTranscriptByEmail({email: user.email, roomCode: activeChat?.roomCode})
		if (res.status === "success") {
            setNotification({ title: getText("Success"), icon: "success" })
		}else{
            setNotification({ title: getText("Something went wrong"), icon: "error" })
        }
	}

	const content = (
		<div className={styles.container}>
			<p className={styles.popupMsg}>{popupMsg}</p>
            <HighlightOffIcon className={styles.icon} />
			{popupText ? <p className={styles.popupText}>{popupText}</p> : null}
            <HearnokButton2 label={getText("Send transcript by email")} icon = {"email"} handleClick = {sendByEmail}/>
            <HearnokButton2 label={getText("Download transcript")} icon = {"download"} handleClick = {downloadPDF}/>
		</div>
	)

	return <HearnokPopup buttonAction={onClick} buttonLabel={buttonLabel || getText("Back to home")} content={content} />
}
export default ChatRoomDestroyedPopup

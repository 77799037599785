import React, { useEffect, useState } from "react"
import { useAppContext, useGlobalContext } from "../../App"
import PageBase from "../common-components/PageBase"
//import TopBar from "../common-components/TopBar"
import styles from "./SearchPage.module.scss"
import HearnokInput from "../common-components/HearnokInput"
import HearnokSelect from "../common-components/HearnokSelect"
import HearnokButton from "../common-components/HearnokButton"
import HearnokInputLabel from "../common-components/HearnokInputLabel"
import HearnokDropDownPage from "../common-components/HearnokDropdownPage"
import { SelectOptions } from "../../types"
import dayjs from "dayjs"
import HearnokDatePicker from "components/common-components/HearnokDatePicker"
import { getGroupIDs } from "apicalls/misc/getGroupIDs"
import { getSearchRooms } from "apicalls/room/getSearchRooms"
import { SearchResultType } from "../../types"
import PDFLogo from "../../assets/Pdf_icon.png"
import { getMessagesTxt } from "../../apicalls/room/getMessagesTxt"
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver'
import { HearnokPdfDocument } from "../common-components/HearnokPDF"

const SearchPage: React.FC = () => {
    const { getText } = useGlobalContext()
    const { user } = useAppContext()

    const [searchText, setSearchText] = useState("")
    const [searchMeetingName, setSearchMeetingName] = useState("")
    const [searchGroupID, setSearchGroupID] = useState(0)
    const [searchStartDate, setSearchStartDate] = useState(dayjs(null));
    const [searchEndDate, setSearchEndDate] = useState(dayjs(null));

    const [groupIDs, setGroupIDs] = useState<SelectOptions>([{value: 0}])

    const [searchResults, setSearchResults] = useState<SearchResultType[]>([])
    const [isAnyResult, setIsAnyResult] = useState(false)

    const [isResultDropdownOpen, setIsResultDropdownOpen] = useState(false);
    const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(true);

    const [windowSize, setWindowSize] = useState([
        window.innerWidth/16,
        window.innerHeight/16,
      ]);

    useEffect(() => {
		getGroupIDs()
		.then(({payload}) => {
			const options :SelectOptions<number> = []
			payload!.groupIDs.forEach( (label, index) => {
				options.push({value: index, label: label})
			})
			setGroupIDs(options)
            setSearchGroupID(user.groupID)
			handleSearch(true, user.groupID)
		});
	}, [user.groupID])//eslint-disable-line react-hooks/exhaustive-deps
	/*The upper comment disable the "React Hook useEffect has missing dependencies" warning*/

    useEffect(() => {
        const handleWindowResize = () => {
            let height = window.innerHeight
            let width = window.innerWidth
            //convert px to rem
            if(width>1920){
                width = width/32
                height = height/32
            }else{
                width = width/16
                height = height/16
            }
            console.log(height)

            setWindowSize([width, height]);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

    const handleSearch = (initSearch :boolean, groupID? :number) => {
        if(initSearch){
            getSearchRooms({text: searchText, meetingName: searchMeetingName, groupID: groupID!, startDate: searchStartDate.format("YYYY-MM-DD"), endDate: searchEndDate.format("YYYY-MM-DD")})
            .then(({ payload }) => {
                setSearchResults(payload!)
                if(payload!?.length > 0){
                    setIsAnyResult(true)
                }else{
                    setIsAnyResult(false)
                }
                setIsResultDropdownOpen(true)
            })
        }else{
            getSearchRooms({text: searchText, meetingName: searchMeetingName, groupID: searchGroupID, startDate: searchStartDate.format("YYYY-MM-DD"), endDate: searchEndDate.format("YYYY-MM-DD")})
            .then(({ payload }) => {
                setSearchResults(payload!)
                if(payload!?.length > 0){
                    setIsAnyResult(true)
                }else{
                    setIsAnyResult(false)
                }
                setIsResultDropdownOpen(true)
                setIsFilterDropdownOpen(false)
            })
        }
        
	}

    async function downloadPDF(roomCode:number) {
		const res = await getMessagesTxt(roomCode)
		if (res.status === "success" && res.payload !== undefined) {
            //console.log(res.payload)
			const doc = HearnokPdfDocument(res.payload.messages, user.pageLanguage, user.translateLanguage)
            const blob = await pdf(doc).toBlob()
		    saveAs(blob, res.payload.roomName+ " - " + res.payload.roomDate + '.pdf')
		}
	}

    const searchResultElements = isAnyResult ? searchResults.map((searchResult, index) => {
		return (
			<div className={styles.resultBlock} key={index}>
                <img className={styles.pdfLogo} src={PDFLogo} alt="pdf_logo"/>
				<div className={styles.resultBlockTexts}>
                    <p className={styles.resultTitle}>{searchResult.title}</p>
                    <p className={styles.resultText}>
                        {searchResult.beforeSearchedText}
                        <mark>{searchResult.searchedText}</mark>
                        {searchResult.afterSearchedText}
                    </p>
                        
                        {/*<p className={styles.name}>{searchResult.beforeSearchedText}</p>
                        {searchResult.beforeSearchedText.charAt(searchResult.beforeSearchedText.length-1)==" " ? (<p>&nbsp;</p>) : null}
                        <p className={styles.searchedText}>{searchResult.searchedText}</p>
                        {searchResult.afterSearchedText.charAt(0)==" " ? (<p>&nbsp;</p>) : null}
                        <p className={styles.name}>{searchResult.afterSearchedText}</p>*/}
				</div>
                <i onClick={() => downloadPDF(searchResult.roomCode)} className={["material-icons", styles.downloadIcon].join(" ")}>
                    download
                </i>
			</div>
		)
	}) : null

    return (
        <PageBase topBar>
            {/*<TopBar/>*/}
            <div className={styles.container}>
                <HearnokDropDownPage className={styles.dropdown} value={isFilterDropdownOpen} setValue= {setIsFilterDropdownOpen} label = {getText("Filters")} maxHeigth={25.5}>
                    <>
                        <HearnokInput
                            value={searchText}
                            setValue={setSearchText}
                            label={getText("Text")}
                            placeholder={getText("Text")}
                        />
                        <HearnokInput
                            value={searchMeetingName}
                            setValue={setSearchMeetingName}
                            label={getText("Meeting name")}
                            placeholder={getText("Meeting name")}
                        />
                        <HearnokSelect
                            options={groupIDs}
                            value={searchGroupID}
                            setValue={setSearchGroupID}
                            label={getText("Group")}
                        />
                        <div className={styles.datePickerContainer}>
                            <HearnokDatePicker
                                label={getText("Start date")}
                                value={searchStartDate}
                                setValue={setSearchStartDate}
                            />
                            <HearnokDatePicker
                                label={getText("End date")}
                                value={searchEndDate}
                                setValue={setSearchEndDate}
                            />
                        </div>
                        <HearnokButton
                            label={getText("Search")}
                            handleClick={() => handleSearch(false)}
                            className={styles.searchButton}
                        />
                    </>
                </HearnokDropDownPage>
                <HearnokDropDownPage 
                    className={styles.dropdown} 
                    value={isResultDropdownOpen} 
                    setValue= {setIsResultDropdownOpen} 
                    label = {getText("Results")} 
                    maxHeigth = {isFilterDropdownOpen ? windowSize[1]-34 : windowSize[1]-10}> 
                    <>
                        {isAnyResult ? (
                            <div className={styles.searchResult}>
                                {searchResultElements}
                            </div>
                        ): (
                            <HearnokInputLabel label={getText("No result")} />
                        )}
                    </>
                </HearnokDropDownPage>
                
            </div>
        </PageBase>
    )
}
export default SearchPage
import { useEffect } from "react"
import { useAppContext } from "../App"

export const useChangeBackAction = (backAction: () => void) => {
	const { setBackAction } = useAppContext()

	useEffect(() => {
		setBackAction({ backAction })
		return () => {
			setBackAction({ backAction: undefined })
		}
	}, []) //eslint-disable-line react-hooks/exhaustive-deps
	/*The upper comment disable the "React Hook useEffect has missing dependencies" warning*/
}

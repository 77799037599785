import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { connectRoom } from "../../apicalls/room/connectRoom"
import { useAppContext, useGlobalContext } from "../../App"
import { ActiveChat, ApiResponse, connectRoomResponse, ConnectRoomResponseMessage } from "../../common/types"
import { onLeaveRoom } from "../../utils/functions"
import HearnokInput from "../common-components/HearnokInput"
import HearnokPopup from "../common-components/HearnokPopup"
import PageBase from "../common-components/PageBase"
import ChatRoomPage from "./ChatRoomPage"
import styles from "./ChatRoomPageWrapper.module.scss"

export const handleConnectedToRoom = (
	res: ApiResponse<ConnectRoomResponseMessage, connectRoomResponse>,
	setActiveChat: React.Dispatch<React.SetStateAction<ActiveChat | null>>,
	history?: any
) => {
	if (!res.payload || res.status !== "success") return false
	setActiveChat((activeChat) => {
		if (!res.payload) return activeChat
		return {
			messages: activeChat ? [...activeChat.messages] : [],
			members: activeChat ? [...activeChat.members] : [],
			roomCode: res.payload.roomCode,
			creatorID: res.payload.creatorID,
			activeUserID: res.payload.creatorID,
			password: res.payload.password,
			isModerated: res.payload.isModerated,
			isDeleted: false,
			isLogEnabled: true
		}
	})
	history?.push({ pathname: "rooms/#" + res.payload?.roomCode })
	return true
}

const ChatRoomPageWrapper: React.FC = () => {
	const { getText, setNotification } = useGlobalContext()
	const { setActiveChat, activeChat, setBackAction } = useAppContext()
	const [isProtected, setIsProtected] = useState(false)
	const [password, setPassword] = useState("")
	const history = useHistory()

	const code = parseInt(window.location.hash.substring(1))

	useEffect(() => {
		setBackAction({ backAction: () => history.push({ pathname: "/" }) })
		if (!activeChat && window.location.hash) {
			const tryToConnect = async () => {
				const res = await connectRoom({ code })
				if (res.message === "Password invalid") {
					setIsProtected(true)
					return
				}
				if (handleConnectedToRoom(res, setActiveChat)) return
				setNotification({ title: getText("Something went wrong"), icon: "error" })
				history.push({ pathname: "/" })
			}
			tryToConnect()
		}
		return () => {
			setBackAction({ backAction: undefined })
			onLeaveRoom(setActiveChat)
		}
	}, []) //eslint-disable-line react-hooks/exhaustive-deps
	/*The upper comment disable the "React Hook useEffect has missing dependencies" warning*/

	const connectWithPassword = async () => {
		const res = await connectRoom({ code, password })
		if (res.message === "Password invalid") {
			setNotification({ icon: "error", title: getText("Invalid password") })
			return
		}
		if (res.status === "error") {
			setNotification({ icon: "error", title: getText("Something went wrong") })
			return
		}
		if (handleConnectedToRoom(res, setActiveChat)) setIsProtected(false)
	}

	if (!activeChat)
		return (
			<PageBase>
				{isProtected ? (
					<HearnokPopup
						buttonLabel={getText("Send")}
						buttonAction={connectWithPassword}
						closeAction={() => history.push({ pathname: "/" })}
						content={
							<div className={styles.popupContent}>
								<HearnokInput
									value={password}
									setValue={setPassword}
									label={getText("Password field label")}
								/>
							</div>
						}
					/>
				) : null}
			</PageBase>
		)
	return <ChatRoomPage />
}
export default ChatRoomPageWrapper

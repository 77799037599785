import React from "react"
import ReactDOM from "react-dom"
import styles from "./HearnokPopup.module.scss"
import HearnokButton from "../common-components/HearnokButton"
import CloseIcon from '@mui/icons-material/Close';

interface Props {
	content: JSX.Element
	buttonLabel: string
	buttonAction: () => void
	closeAction?: (event :any) => void
	loadingProp?: boolean
	contentAction?:(event :any) => void
}

const HearnokPopup: React.FC<Props> = ({ content, buttonLabel, buttonAction, closeAction, loadingProp, contentAction }) => {
	return ReactDOM.createPortal(
		<div className={styles.container} onClick = {contentAction}>
			<div className={styles.popupContainer}>
				<div className={styles.content}>{content}</div>
				{closeAction ? (
					<CloseIcon onClick={closeAction} className={styles.icon} />
				) : null}
				<HearnokButton
					label={buttonLabel}
					handleClick={buttonAction}
					className={styles.button}
					loadingProp={loadingProp}
				/>

				{/*<div className={styles.button} onClick={action}>
					{buttonLabel}
				</div>*/}
			</div>
		</div>,
		document.body
	)
}

export default HearnokPopup

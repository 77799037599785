import axios from "axios"
import { ApiResponse } from "../../common/types"
import { APIBASEURL } from "../../config"

export const requestPasswordResetEmail = async (email: string) => {
	const res = await axios(APIBASEURL + "forgot-password", {
		method: "POST",
		data: { email },
	})
	console.log(res)
	return (res.data as ApiResponse) || null
}

import React from "react"
import { useGlobalContext } from "../../App"
import styles from "./MicPopup.module.scss"
import HearnokPopup from "../common-components/HearnokPopup"
import MicNoneIcon from '@mui/icons-material/MicNone';

interface Props {
	buttonLabel?: string
	popupMsg?: string
	popupText?: string
	action: () => void
    loadingProp?: boolean
}

const MicPopup: React.FC<Props> = ({ buttonLabel, popupMsg, popupText, action, loadingProp }) => {
	const { getText } = useGlobalContext()

	const content = (
		<div className={styles.container}>
			{/*<i className={["material-icons", styles.icon].join(" ")}>error</i>*/}
			<p className={styles.popupMsg}>{popupMsg || getText("Something went wrong")}</p>
			{popupText ? <p className={styles.popupText}>{popupText}</p> : null}
            <MicNoneIcon className={styles.icon} />
		</div>
	)

	return (
		<HearnokPopup buttonLabel={buttonLabel || getText("Back")} content={content} buttonAction={action} loadingProp={loadingProp} />
	)
}
export default MicPopup
export class Queue<T> {
	private array: T[]
	private size: number

	constructor(size: number) {
		this.size = size
		this.array = []
	}

	push(v: T) {
		this.array.push(v)
		if (this.array.length > this.size) this.array.shift()
	}

	empty() {
		this.array = []
	}

	getArray() {
		return this.array
	}
}

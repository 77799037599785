import { LinearProgress } from "@material-ui/core"
import React from "react"
import { useGlobalContext } from "../../App"
import styles from "./NoiseGateSetting.module.scss"
import Slider from "@material-ui/core/Slider"

interface Props {
	volume: number
	setNoiseGateValue: (v: number) => void
	noiseGateValue: number
}

const NoiseGateSetting: React.FC<Props> = ({ setNoiseGateValue, volume, noiseGateValue }) => {
	const { getText } = useGlobalContext()

	const sliderScale = 2; //original slider: 0-100 value; scaled slider: 0-50 value

	//console.log(noiseGateValue)

	return (
		<div className={styles.outer}>
			<p>{getText("Set mic sensitivity help")}</p>
			<div className={styles.container}>
				<LinearProgress variant="determinate" value={volume <= 100 / sliderScale ? volume * sliderScale : 100} />
				<div className={styles.slider}>
					<Slider value={noiseGateValue*sliderScale} onChange={(e, v) => setNoiseGateValue((v as number) / sliderScale)} />
				</div>
			</div>
		</div>
	)
}
export default NoiseGateSetting

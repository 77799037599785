import { CircularProgress } from "@material-ui/core"
import React, { useEffect, useRef, useState } from "react"
import styles from "./Loader.module.scss"

interface Props {
	label?: string
}

const Loader: React.FC<Props> = ({ label }) => {
	const ref = useRef<HTMLDivElement>(null)
	const [containerRendered, setContainerRendered] = useState(false)

	useEffect(() => {
		setContainerRendered(true)
	}, [])

	let size = ref.current
		? ref.current.offsetHeight > ref.current.offsetWidth
			? ref.current.offsetWidth
			: ref.current.offsetHeight
		: "1.4rem"

	return (
		<div ref={ref} className={styles.container}>
			{containerRendered ? (
				<>
					<CircularProgress size={size}/>
					{label ? <p>{label}</p> : null}
				</>
			) : null}
		</div>
	)
}
export default Loader

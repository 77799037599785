import axios from "axios"
import { ApiResponse } from "../../common/types"
import { APIBASEURL } from "../../config"
import { Participant } from "../../common/types"

export async function getChatParticipants(roomCode: number) {
	const res = await axios(APIBASEURL + "chat-participants/" + roomCode, {
		method: "GET",
		withCredentials: true,
	})
	return res.data as ApiResponse<any, Participant[]>
}
import axios from "axios"
import {
	ApiResponse,
	updatePreferencesPayload,
	updatePreferencesErrorMessages,
	UserClientData,
} from "../../common/types"
import { APIBASEURL } from "../../config"

export const updatePreferences = async (data: Partial<UserClientData>) => {
	const res = await axios(APIBASEURL + "update-pref", {
		method: "POST",
		data,
		withCredentials: true,
	})
	//console.log(res.data)
	return (res.data as ApiResponse<updatePreferencesErrorMessages, updatePreferencesPayload>) || null
}

import http from "../../common/http-common";

const upload = async (file: File, onUploadProgress: any): Promise<any> => {
  let formData = new FormData();

  formData.append("file", file);

  return http.post("upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
};

const upload_old = async (file: File, roomCode: string, onUploadProgress: any): Promise<any> => {
  let formData = new FormData();

  formData.append("file", file);
  formData.append("roomCode", roomCode);

  return http.post("upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
};

const getFiles = () : Promise<any> => {
  return http.get("files");
};

const fileUploadService = {
  upload,
  upload_old,
  getFiles,
};

export default fileUploadService;
import axios from "axios"
import { ApiResponse, finishEmailChangeErrorMessages } from "../../common/types"
import { APIBASEURL } from "../../config"

export const finishEmailChange = async (emailChangeKey: string) => {
	const res = await axios({
		method: "post",
		url: APIBASEURL + "finish-email-change",
		data: { emailChangeKey },
		withCredentials: true,
	})
	return res.data as ApiResponse<finishEmailChangeErrorMessages>
}

import { Text } from "./types"

export interface OneLiners {
	"Password reset email subject": Text
	"Email field label": Text
	"Password field label": Text
	"Login button": Text
	"Forgot password?": Text
	"No account": Text
	"Guest explanation": Text
	"Display name label": Text
	"Continue as guest": Text
	"Already have account": Text
	"Back to home": Text
	Register: Text
	"Transcription language": Text
	"Register - change later info": Text
	"Field necessary": Text
	"Password too short": Text
	"Incorrect email format": Text
	"Invalid registration key": Text
	Error: Text
	Back: Text
	"Something went wrong": Text
	"Invalid regkey explanation": Text
	"Add money to balance": Text
	Balance: Text
	Minutes: Text
	"STT service": Text
	English: Text
	Hungarian: Text
	"Change password": Text
	"Change email": Text
	"Delete account": Text
	Logout: Text
	Save: Text
	"Old password label": Text
	"New password label": Text
	"New password repeat label": Text
	"New email address label": Text
	"Delete account info": Text
	"Password reset email title": Text
	Activate: Text
	"Activate email title": Text
	"Active email subject": Text
	"Successful registration info": Text
	"Activation unsuccessful info": Text
	"Activation successful": Text
	"Invalid credentials info": Text
	"Email not activated": Text
	"Password reset email sent": Text
	Send: Text
	"Passwords are not the same": Text
	"Delete account email subject": Text
	"Delete account email text": Text
	"Preferences successfully updated": Text
	"Password changed": Text
	"Invalid password": Text
	Success: Text
	"Create new room": Text
	"Room link or code": Text
	Connect: Text
	"Room password": Text
	Optional: Text
	"Room does not exist": Text
	Share: Text
	More: Text
	"Room code": Text
	"Room has been closed": Text
	"Insufficient funds": Text
	"Room closed due to depleted balance": Text
	Start: Text
	"Mic start info": Text
	"Left the room": Text
	"Joined the room": Text
	"Set mic sensitivity help": Text
	"Chat members": Text
	"Save messages": Text
	"Mic sensitivity": Text
	"Confirm close room text": Text
	"Invalid email change key": Text
	"Email taken": Text
	"Email change info": Text
	"Activation code": Text
	Continue: Text
	"Delete code expired": Text
	"Delete code invalid": Text
	"Moderated": Text
	"Non-moderated": Text
	"Room name": Text
	"Group": Text
	"Meeting type":Text
	"Upload file":Text
	"Mute all":Text
	"Start log":Text
	"Stop log":Text
	"Guest": Text
	"Search": Text
	"Text": Text
	"Meeting name": Text
	"Start date": Text
	"End date": Text
	"Results": Text
	"No result": Text
	"Powered by": Text
	"Connect to room": Text
	"Settings": Text
	"Welcome": Text
	"Welcome to the": Text
	"Platform": Text
	"Sign in or create an account": Text
	"Filters": Text
	"Sign in as Guest": Text
	"Forgotten Password": Text
	"Forgot password explanation": Text
	"Set new password": Text
	"Forgot password after Send explanation": Text
	"Create": Text
	"Upload": Text
	"Register info": Text
	"Email field repeat label": Text
	"Password field repeat label": Text
	"Next": Text
	"Emails are not the same": Text
	"Company": Text
	"Speciality": Text
	"Position": Text
	"Phone number": Text
	"Enable mic": Text
	"Enable": Text
	"Confirm close room title":Text
	"Send transcript by email": Text
	"Download transcript": Text
	"Room has been closed text":Text
	"Log title": Text
	"Unsuccess": Text
	"Change user data": Text
	"Looking for": Text
	"Translation language": Text
	"Dutch": Text
	"French": Text
	"German": Text
	"Hindi": Text
	"Italian": Text
	"Polish": Text
	"Russian": Text
	"Spanish": Text
	"Portuguese": Text
	"Delete account email content": Text
	"Email change email subject": Text
	"Email change email title": Text
	"Email change email content": Text
	"Room connection email subject": Text
	"Room connection email title": Text
	"Transcript email subject": Text
	"Transcript email title": Text
	"Transcript email content": Text
	"Email already in use": Text
	"Registration key invalid": Text
	"Latvian":Text
	"Lithuanian":Text
	"Belarusian":Text
	"Estionian":Text
	"Chinese":Text
	"Password reset key expired": Text
	"Rating popup title": Text
	"Rating popup text": Text
	"Rate us": Text
	"Thank you for rating us": Text
	"You have not set a rating": Text
}

export const oneLiners: OneLiners = {
	"Password reset email subject": {
		en: "Password reset",
		hu: "Jelszó visszaállítás",
	},
	"Email field label": {
		en: "E-mail",
		hu: "E-mail",
	},
	"Password field label": {
		en: "Password",
		hu: "Jelszó",
	},
	"Login button": {
		en: "Login",
		hu: "Bejelentkezés",
	},
	"Forgot password?": {
		en: "Forgot password?",
		hu: "Elfelejtette a jelszavát?",
	},
	"No account": {
		en: "New to Hearnok?",
		hu: "Még nincs fiókja?",
	},
	"Guest explanation": {
		en: "If you do not have an account, you can continue as a guest. You only need to enter a name and an email address.",
		hu: "Ha nincs még fiókja, akkor vendégként van lehetősége folytatni. Ehhez csak egy nevet és egy email címetkell megadnia.",
	},
	"Display name label": {
		en: "Display name",
		hu: "Név",
	},
	"Continue as guest": {
		en: "Continue as guest",
		hu: "Belépek vendégként",
	},
	"Already have account": {
		en: "Already have account?",
		hu: "Már van fiókja?",
	},
	"Back to home": {
		en: "Back to homepage",
		hu: "Vissza a főoldalra",
	},
	Register: {
		en: "Register",
		hu: "Regisztráció",
	},
	"Transcription language": {
		en: "Transcription language",
		hu: "Beszéd nyelve",
	},
	"Register - change later info": {
		en: "You can change this setting later in your personal preferences",
		hu: "Ezt később akármikor megváltoztathatja a beállításokban",
	},
	"Field necessary": {
		en: "Field necessary",
		hu: "Mező kitöltése kötelező",
	},
	"Password too short": {
		en: "Password must be at least 8 characters",
		hu: "A jelszónak legalább 8 karakternek kell lennie",
	},
	"Incorrect email format": {
		en: "Incorrect email",
		hu: "Helytelen email cím",
	},
	"Invalid registration key": {
		en: "Invalid registration key",
		hu: "A megadott meghívólink érvénytelen",
	},
	Error: {
		en: "Error",
		hu: "Hiba",
	},
	Back: {
		en: "Back",
		hu: "Vissza",
	},
	"Something went wrong": {
		en: "Something went wrong",
		hu: "Valami hiba történt",
	},
	"Invalid regkey explanation": {
		en: "Your invitation link is invalid. Someone probably has already used it.",
		hu: "A meghívólink nem megfelelő. Valószínűleg már valaki felhasználta.",
	},
	"Add money to balance": {
		en: "Add balance",
		hu: "Egyenleg feltöltése",
	},
	Balance: {
		en: "Balance",
		hu: "Egyenleg",
	},
	Minutes: {
		en: "Minutes",
		hu: "Perc",
	},
	"STT service": {
		en: "STT service",
		hu: "STT szolgáltató",
	},
	English: {
		en: "English",
		hu: "Angol",
	},
	Hungarian: {
		en: "Hungarian",
		hu: "Magyar",
	},
	"Change email": {
		en: "Change e-mail address",
		hu: "E-mail cím megváltoztatása",
	},
	"Change password": {
		en: "Change password",
		hu: "Jelszó megváltoztatása",
	},
	"Delete account": {
		en: "Delete account",
		hu: "Fiók törlése",
	},
	Logout: {
		en: "Log out",
		hu: "Kijelentkezés",
	},
	Save: {
		en: "Save",
		hu: "Mentés",
	},
	"New password label": {
		en: "New password",
		hu: "Új jelszó",
	},
	"New password repeat label": {
		en: "New password again",
		hu: "Új jelszó ismét",
	},
	"Old password label": {
		en: "Old password",
		hu: "Régi jelszó",
	},
	"New email address label": {
		en: "New email address",
		hu: "Új email cím",
	},
	"Delete account info": {
		en: "An e-mail has been sent to your address. Please enter the received code below.",
		hu: "Egy e-mail-t küldtünk a megadott címre. Adja meg a kapott kódot.",
	},
	Activate: {
		en: "Activate",
		hu: "Aktiválás",
	},
	"Password reset email title": {
		en: "Click the button to change your password",
		hu: "Kattintson a gombra a jelszó megváltoztatásához",
	},
	"Activate email title": {
		en: "Click the button to activate your email",
		hu: "Kattints a gombra, hogy aktiváld az email-ed",
	},
	"Active email subject": {
		en: "Hearnok account email activation",
		hu: "Hearnok fiók email aktiválás",
	},
	"Successful registration info": {
		en: "An email has been sent to your address. Click the button to finish your registration!",
		hu: "Küldtünk egy email a megadott e-mail címre. Kattintson a benne található gombra, hogy befejezze a regisztrációt!",
	},
	"Activation unsuccessful info": {
		en: "Your email activation was unsuccessful. Try it in a few minutes or if the error persists contact support.",
		hu: "Az email aktiválása sikertelen volt. Próbálja újra pár perc múlva és ha a probléma továbbra is fent áll, vegye fel a kapcsolatot a support-tal.",
	},
	"Activation successful": {
		en: "Activation successful",
		hu: "Sikeres aktiválás",
	},
	"Invalid credentials info": {
		en: "The given email or password is not correct",
		hu: "A megadott email vagy jelszó helytelen",
	},
	"Email not activated": {
		en: "Email is not activated",
		hu: "Az email cím nincs aktiválva",
	},
	"Password reset email sent": {
		en: "An email has been sent to the provided email address if there is a user associated with it.",
		hu: "Ha létezik regisztrált felhasználó a megadott email címmel, hamarosan érkezni fog egy email.",
	},
	Send: {
		en: "Send",
		hu: "Küldés",
	},
	"Passwords are not the same": {
		en: "The passwords do not match",
		hu: "A jelszavak nem egyeznek",
	},
	"Delete account email subject": {
		en: "Delete account",
		hu: "Fiók törlése",
	},
	"Delete account email text": {
		en: "Delete account",
		hu: "Fiók törlése",
	},
	"Preferences successfully updated": {
		en: "Preferences successfully updated",
		hu: "Beállítások frissítve",
	},
	"Password changed": {
		en: "Password changed",
		hu: "Jelszó megváltoztatva",
	},
	"Invalid password": {
		en: "Invalid password",
		hu: "Hibás jelszó",
	},
	Success: {
		en: "Success",
		hu: "Sikeres művelet",
	},
	"Create new room": {
		en: "Create room",
		hu: "Szoba készítése",
	},
	"Room link or code": {
		en: "Room link or code",
		hu: "Szoba link vagy kód",
	},
	Connect: {
		en: "Connect",
		hu: "Csatlakozás",
	},
	"Room password": {
		en: "Room password",
		hu: "Szoba jelszó",
	},
	Optional: {
		en: "Optional",
		hu: "Opcionális",
	},
	"Room does not exist": {
		en: "Room does not exist",
		hu: "A szoba nem létezik",
	},
	Share: {
		en: "Invite",
		hu: "Meghívás",
	},
	More: {
		en: "More",
		hu: "Egyéb",
	},
	"Room code": {
		en: "Room code",
		hu: "Szoba kódja",
	},
	"Room has been closed": {
		en: "The chat room has been closed",
		hu: "A chat szobát bezárták",
	},
	"Insufficient funds": {
		en: "To open a room you must have at least 15 minutes of balance",
		hu: "A szoba megnyitásához legalább 15 percnyi egyenleg szükséges",
	},
	"Room closed due to depleted balance": {
		en: "Room closed due to depleted balance",
		hu: "A szoba bezáródott mert az egyenlege elfogyott",
	},
	Start: {
		en: "Start",
		hu: "Indítás",
	},
	"Mic start info": {
		en: "Allow access to your microphone for the app to run smoothly.",
		hu: "Az alkalmazás gördülékeny működéseérdekében engedélyezze a hozzáférést mikrofonjához.",
	},
	"Joined the room": {
		en: "joined the room",
		hu: "csatlakozott a szobához",
	},
	"Left the room": {
		en: "left the room",
		hu: "elhagyta a szobát",
	},
	"Set mic sensitivity help": {
		en: "Set the microphone's sensitivity so that it picks up your voice but not the background noise",
		hu: "Állítsa be a mikrofon érzékenységét úgy, hogy a háttérzajt ne vegye fel",
	},
	"Save messages": {
		en: "Save messages",
		hu: "Üzenetek mentése",
	},
	"Chat members": {
		en: "Chat members",
		hu: "Chat résztvevői",
	},
	"Mic sensitivity": {
		en: "Microphone sensitivity",
		hu: "Mikrofon érzékenység",
	},
	"Confirm close room text": {
		en: "Are you sure you want to close the chatroom?",
		hu: "Biztos benne, hogy be akarja zárni a chatszobát?",
	},
	"Invalid email change key": {
		en: "Invalid code",
		hu: "Helytelen kód",
	},
	"Email taken": {
		en: "Email taken",
		hu: "A megadott e-mail cím már használatban van",
	},
	"Email change info": {
		en: "An email has been sent to the provided address with an activation code.",
		hu: "Egy email-t küldtünk a megadott címre, amely tartalmazza az aktivációs kódot.",
	},
	"Activation code": {
		en: "Activation code",
		hu: "Aktivációs kód",
	},
	Continue: {
		en: "Continue",
		hu: "Folytatás",
	},
	"Delete code expired": {
		en: "Code expired",
		hu: "Lejárt kód",
	},
	"Delete code invalid": {
		en: "Code invalid",
		hu: "Helytelen kód",
	},
	"Moderated": {
		en: "Moderated",
		hu: "Moderált",
	},
	"Non-moderated": {
		en: "Non-moderated",
		hu: "Szabad",
	},
	"Room name": {
		en: "Room name",
		hu: "Szoba neve",
	},
	"Group": {
		en: "Group",
		hu: "Csoport",
	},
	"Meeting type": {
		en: "Meeting type",
		hu: "Találkozó típusa",
	},
	"Upload file": {
		en: "Upload file",
		hu: "Fájl feltöltése",
	},
	"Mute all": {
		en: "Mute all",
		hu: "Összes némítása",
	},
	"Start log": {
		en: "Start log",
		hu: "Start log",
	},
	"Stop log": {
		en: "Stop log",
		hu: "Stop log",
	},
	"Guest": {
		en: "Guest",
		hu: "Vendég",
	},
	"Search": {
		en: "Search",
		hu: "Keresés",
	},
	"Text": {
		en: "Text",
		hu: "Szöveg",
	},
	"Meeting name": {
		en: "Meeting name or ID",
		hu: "Megbeszélés neve",
	},
	"Start date": {
		en: "Start date",
		hu: "Kezdő dátum",
	},
	"End date": {
		en: "End date",
		hu: "Végdátum",
	},
	"Results":{
		en: "Results",
		hu: "Találatok",
	},
	"No result":{
		en: "No result",
		hu: "Nincs találat",
	},
	"Powered by":{
		en: "Powered by",
		hu: "Készítette",
	},
	"Connect to room":{
		en: "Connect to room",
		hu: "Csatlakozás szobához",
	},
	"Settings":{
		en: "Settings",
		hu: "Beállítások",
	},
	"Welcome":{
		en: "Welcome",
		hu: "Köszöntjük",
	},
	"Welcome to the":{
		en: "Welcome to the",
		hu: "A",
	},
	"Platform":{
		en: "platform!",
		hu: "felületén!",
	},
	"Sign in or create an account":{
		en: "Sign in or create an account.",
		hu: "Jelentkezzen be, vagy készítsen fiókot.",
	},
	"Filters":{
		en: "Filters",
		hu: "Szűrők",
	},
	"Sign in as Guest": {
		en: "Sign in as Guest",
		hu: "Belépés Vendégként",
	},
	"Forgotten Password": {
		en: "Forgotten Password",
		hu: "Elfelejtett Jelszó",
	},
	"Forgot password explanation": {
		en: "Enter your registered email address, to access your Hearnok X profile again.",
		hu: "Adja meg regisztrált email címét, hogy ismét elérje Hearnok X profilját.",
	},
	"Set new password": {
		en: "Please set your new password.",
		hu: "Kérjük, állítsa be új jelszavát.",
	},
	"Forgot password after Send explanation": {
		en: "After pressing the Send button check your email account.",
		hu: "A Küldés gomb megnyomása után ellenőrizze email fiókját.",
	},
	"Create":{
		en: "Create",
		hu: "Létrehozás",
	}, 
	"Upload":{
		en: "Upload",
		hu: "Feltöltés",
	},
	"Register info":{
		en: "Create your new Hearnok X account.",
		hu: "Készítse el új Hearnok X fiókját.",
	},
	"Email field repeat label": {
		en: "E-mail again",
		hu: "E-mail ismét",
	},
	"Password field repeat label":{
		en: "Password again",
		hu: "Jelszó ismét",
	},
	"Next":{
		en: "Next",
		hu: "Tovább",
	},
	"Emails are not the same": {
		en: "The emails don't match",
		hu: "Az e-mailek nem egyeznek",
	},
	"Company":{
		en: "Company",
		hu: "Cégnév",
	},
	"Speciality":{
		en: "Speciality",
		hu: "Szakterület",
	},
	"Position":{
		en: "Position",
		hu: "Pozíció",
	},
	"Phone number":{
		en: "Phone number",
		hu: "Telefonszám",
	},
	"Looking for":{
		en: "Looking for",
		hu: "Amit keresek",
	},
	"Enable mic":{
		en: "Enable Microphone",
		hu: "Mikrofon Engedélyezése",
	},
	"Enable":{
		en: "Enable",
		hu: "Engedélyezem",
	},
	"Confirm close room title": {
		en: "Are you sure?",
		hu: "Biztos benne?",
	},
	"Send transcript by email": {
		en: "Send transcript by email",
		hu: "Leírat küldése emailben",
	},
	"Download transcript":{
		en: "Download transcript",
		hu: "Leírat letöltése",
	},
	"Room has been closed text": {
		en: "The owner of the room left from the meeting.",
		hu: "A szoba tulajdonosa kilépett a beszélgetésből.",
	},
	"Log title": {
		en: "Logging",
		hu: "Logolás",
	},
	"Unsuccess": {
		en: "Unsuccess",
		hu: "Sikertelen művelet",
	},
	"Change user data":{
		en: "Change user data",
		hu: "Felhasználói adatok megváltoztatása",
	},"Translation language": {
		en: "Translation language",
		hu: "Fordítás nyelve",
	},
	"Dutch":{
		en: "Dutch",
		hu: "Holland",
	},
	"French":{
		en: "French",
		hu: "Francia",
	},
	"German":{
		en: "German",
		hu: "Német",
	},
	"Hindi":{
		en: "Hindi",
		hu: "Hindi",
	},
	"Italian":{
		en: "Italian",
		hu: "Olasz",
	},
	"Polish":{
		en: "Polish",
		hu: "Lengyel",
	},
	"Russian":{
		en: "Russian",
		hu: "Orosz",
	},
	"Spanish":{
		en: "Spanish",
		hu: "Spanyol",
	},
	"Portuguese":{
		en: "Portuguese",
		hu: "Portugál",
	},
	"Delete account email content":{
		en: "You can delete your account with this code!",
		hu: "Ezzel a kóddal tudja törölni fiókját!",
	},
	"Email change email subject":{
		en: "Hearnok account email activation",
		hu: "Hearnok fiók email aktiválás",
	},
	"Email change email title":{
		en: "Hearnok account email activation",
		hu: "Hearnok fiók email aktiválás",
	},
	"Email change email content":{
		en: "You can use this code to change the email address of your account!",
		hu: "Ezzel a kóddal tudja megváltoztatni fiókja email címét!",
	},
	"Room connection email subject":{
		en: "Connect to room",
		hu: "Csatlakozz a szobához",
	},
	"Room connection email title":{
		en: "You've been invited to join a Hearnok room!",
		hu: "Meghívtak, hogy csatlakozz egy Hearnok szobához!",
	},
	"Transcript email subject":{
		en: "Hearnok meeting transcript",
		hu: "Hearnok értekezlet jegyzőkönyve",
	},	
	"Transcript email title":{
		en: "Hearnok meeting transcript",
		hu: "Hearnok értekezlet jegyzőkönyve",
	},
	"Transcript email content":{
		en: "You can find the transcript of the Hearnok meeting in the email attachment!",
		hu: "Az email csatolmányában megtalálod a Hearnok értekezlet jegyzőkönyvét!",
	},
	"Email already in use":{
		en: "Email already in use",
		hu: "Ezzel az email címmel már regisztráltak",
	},
	"Registration key invalid":{
		en: "Registration key invalid",
		hu: "A regisztrációs kulcs érvénytelen",
	},
	"Latvian":{
		en: "Latvian",
		hu: "Lett",
	},
	"Lithuanian":{
		en: "Lithuanian",
		hu: "Litván",
	},
	"Belarusian":{
		en: "Belarusian",
		hu: "Belorusz",
	},
	"Estionian":{
		en: "Estionian",
		hu: "Észt",
	},
	"Chinese":{
		en: "Chinese",
		hu: "Kínai",
	},
	"Password reset key expired": {
		en: "Password reset key expired",
		hu: "Jelszó visszaállító kód lejárt",
	},
	"Rating popup title": {
		en: "Please rate us",
		hu: "Kérlek, értékelj bennünket",
	},
	"Rating popup text": {
		en: "Your opinion is important to us. Write a few words about how you think Hearnok X works. Thank you.",
		hu: "Számunkra fontos a véleményed. Írd le pár szóban mit gondolsz a Hearnok X működéséről. Köszönjük.",
	},
	"Rate us": {
		en: "Rate us",
		hu: "Értékelj bennünket",
	},
	"Thank you for rating us": {
		en: "Thank you for rating us",
		hu: "Köszönjük, hogy értékelt bennünket",
	},
	"You have not set a rating": {
		en: "You have not set a rating",
		hu: "Nem állítottál be értékelést",
	},
	
}

import React, { useState } from "react"
import { useAppContext, useGlobalContext } from "../../App"
import { useChangeBackAction } from "../../utils/hooks"
import HearnokButton from "../common-components/HearnokButton"
import HearnokInput from "../common-components/HearnokInput"
import styles from "./ChangeUserData.module.scss"
import { updatePreferences } from "apicalls/account/updatePreferences"


interface Props {
	setTransformed?: (val: boolean) => void
	className?: string
	setRenderedPanelToNull?: () => void
}

const ChangeUserData: React.FC<Props> = ({
	setTransformed,
	className,
    setRenderedPanelToNull,
}) => {
    const { getText, setNotification } = useGlobalContext()
    const { user,setUser } = useAppContext()
    const [company, setCompany] = useState(user.company || "")
	const [speciality, setSpeciality] = useState(user.speciality || "")
    const [position, setPosition] = useState(user.position || "")
	const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber || "")
    const [lookingFor, setLookingFor] = useState(user.lookingFor || "")

    const backAction = () => {
        setTransformed?.(false)
        setRenderedPanelToNull?.()
    }
    useChangeBackAction(backAction)

    const submit = async () => {
        const res = await updatePreferences({ company, speciality, position, phoneNumber, lookingFor })
		if (res?.status === "success") {
			setNotification({ title: getText("Preferences successfully updated"), icon: "success" })
			setUser(res.payload || null)
		} else setNotification({ title: getText("Something went wrong"), icon: "error" })
        setTransformed?.(false)
        setRenderedPanelToNull?.()
    }

    return (
        <div className={className || styles.container}>
            <HearnokInput
                label={`${getText("Company")} (${getText("Optional").toLowerCase()})`}
                value={company}
                setValue={setCompany}
            />
            <HearnokInput
                label={`${getText("Speciality")} (${getText("Optional").toLowerCase()})`}
                value={speciality}
                setValue={setSpeciality}
            />
            <HearnokInput
                label={`${getText("Position")} (${getText("Optional").toLowerCase()})`}
                value={position}
                setValue={setPosition}
            />
            <HearnokInput
                label={`${getText("Phone number")} (${getText("Optional").toLowerCase()})`}
                value={phoneNumber}
                setValue={setPhoneNumber}
            />
            <HearnokInput
                label={`${getText("Looking for")} (${getText("Optional").toLowerCase()})`}
                value={lookingFor}
                setValue={setLookingFor}
            />
            <HearnokButton
                label={getText("Save")}
                className={["centered", styles.button].join(" ")}
                handleClick={submit}
            />
        </div>
    )
}
export default ChangeUserData